









































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import UploadImage from "@/components/shared/forms/UploadImage.vue";
import Toggle from "@/components/shared/forms/Toggle.vue";
import { ITenantDTO } from "../../../../app/models/master/account/ITenantDTO";
import { appThemes, appColors } from "../../../../store/modules/theme/themes";
import { Console } from "console";
import { defaultState } from "../../../../store/modules/theme";

@Component({
  components: { SuccessModal, ErrorModal, UploadImage, Toggle }
})
export default class TenantSettingsComponent extends BaseComponent {
  imageType: string = "";
  imageCurrent: string = "";
  tenant = {} as ITenantDTO;
  showUploadImage = false;
  appThemes = appThemes;
  appColors = appColors;
  created() {
    this.getCurrentTenantInForm();
  }
  getCurrentTenantInForm() {
    this.tenant.name = this.$store.state.tenant.current.name;
    this.tenant.subdomain = this.$store.state.tenant.current.subdomain;
    this.tenant.domain = this.$store.state.tenant.current.domain;
    this.tenant.appTheme =
      this.$store.state.tenant.appTheme ?? defaultState.appTheme;
    this.tenant.appColor =
      this.$store.state.tenant.appColor ?? defaultState.appColor;
  }
  updateTenant() {
    this.services.tenants
      .update(this.tenant)
      .then(response => {
        this.services.tenants.getMyTenants().then(() => {
          this.getCurrentTenantInForm();
        });
        // @ts-ignore
        this.$refs["success-modal"].show(this.$t("settings.tenant.updated"));
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  uploadImage(type) {
    this.imageType = type;
    if (type === "icon") {
      this.imageCurrent = this.tenantIcon;
    } else if (type === "logo") {
      this.imageCurrent = this.tenantLogo;
    } else if (type === "logoDarkmode") {
      this.imageCurrent = this.tenantLogoDarkmode;
    }
    this.showUploadImage = true;
  }
  deleteImage(type) {
    this.imageType = type;
    this.loadedImage(null);
  }
  loadedImage(image) {
    const payload: any = {};
    if (this.imageType === "logo") {
      payload.logo = image;
    } else if (this.imageType === "icon") {
      payload.icon = image;
    } else if (this.imageType === "logoDarkmode") {
      payload.logoDarkmode = image;
    }
    this.services.tenants
      // @ts-ignore
      .uploadImage(payload, this.imageType)
      .then(response => {
        this.$store.commit("tenant/currentImage", {
          imageType: this.imageType,
          image
        });
        this.showUploadImage = false;
      })
      .catch(error => {
        console.error("Error: " + error);
      });
  }
  get tenantIcon() {
    return this.$store.state.tenant.current.icon;
  }
  get tenantLogo() {
    return this.$store.state.tenant.current.logo;
  }
  get tenantLogoDarkmode() {
    return this.$store.state.tenant.current.logoDarkmode;
  }
}
