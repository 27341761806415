






























































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import { Watch, Prop } from "vue-property-decorator";

@Component({
  components: { SuccessModal, ErrorModal }
})
export default class FaqComponent extends BaseComponent {
  @Prop({ default: false }) fullPage!: boolean;
  questions: any = [];
  expanded: any = [];
  mounted() {
    this.loadQuestions();
  }
  loadQuestions() {
    this.questions = [
      {
        question: this.$t("marketing.faq.question_1"),
        answer: this.$t("marketing.faq.answer_1")
      },
      {
        question: this.$t("marketing.faq.question_2"),
        answer: this.$t("marketing.faq.answer_2")
      },
      {
        question: this.$t("marketing.faq.question_3"),
        answer: this.$t("marketing.faq.answer_3")
      },
      {
        question: this.$t("marketing.faq.question_4"),
        answer: this.$t("marketing.faq.answer_4")
      }
      // {
      //   question: this.$t("marketing.faq.question_5"),
      //   answer: this.$t("marketing.faq.answer_5"),
      // },
      // {
      //   question: this.$t("marketing.faq.question_6"),
      //   answer: this.$t("marketing.faq.answer_6"),
      // },
    ];
    this.expanded = [];
    this.expanded.push(this.questions[0].question);
  }
  toggle(question) {
    if (this.expanded.includes(question.question)) {
      this.expanded = this.expanded.filter(item => item !== question.question);
    } else {
      this.expanded.push(question.question);
    }
  }
  isExpanded(question) {
    return this.expanded.includes(question.question);
  }
  @Watch("$store.state.locale.locale")
  watchSomething() {
    this.loadQuestions();
  }
}
