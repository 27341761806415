import { StripeProduct } from "@/app/models/subscription/StripeProduct";
import { StripeFeature } from "@/app/models/subscription/StripeFeature";
import {
  BillingPeriod,
  PriceType,
} from "@/app/models/subscription/StripePrice";

export const defaultProducts: StripeProduct[] = [
         {
           stripeId: "prod_HtKSVxeKZF3s2l",
           tier: 1,
           title: "Standard 🔥",
           description: "prod_HtKSVxeKZF3s2l",
           prices: [
             {
               stripeId: "price_1HKa9YAlf1asoVHVEYuNXhAt",
               type: PriceType.OneTime,
               billingPeriod: BillingPeriod.Once,
               price: 239,
               currency: "usd",
               trialDays: 0,
               active: true,
               priceBefore: 299,
             },
             {
               stripeId: "price_1HKaAUAlf1asoVHVoXNibftE",
               type: PriceType.OneTime,
               billingPeriod: BillingPeriod.Once,
               price: 4599,
               currency: "mxn",
               trialDays: 0,
               active: true,
               priceBefore: 5799,
             },
             {
               stripeId: "price_1HNtXZAlf1asoVHVLUnxjDhO",
               type: PriceType.OneTime,
               billingPeriod: BillingPeriod.Once,
               price: 199,
               currency: "eur",
               trialDays: 0,
               active: true,
               priceBefore: 249,
             },
           ],
           features: [
             {
               key: "frontendBackend",
               value: "",
               included: true,
             },
             {
               key: "upToProjects",
               value: "3",
               included: true,
             },
             {
               key: "upToDevelopers",
               value: "3",
               included: true,
             },
             {
               key: "1yearAccess",
               value: "",
               included: true,
             },

             {
               key: "revenue",
               value: "<",
               included: true,
             },

             {
               key: "prioritySupport",
               value: "",
               included: false,
             },
           ],
           badge: "mostPopular",
           active: true,
           maxUsers: 3,
         },
         {
           stripeId: "prod_HtKSw97w8cYpvB",
           tier: 2,
           title: "Enterprise 🚀",
           description: "",
           prices: [
             {
               stripeId: "price_1HKaBrAlf1asoVHVRmAFjthQ",
               type: PriceType.OneTime,
               billingPeriod: BillingPeriod.Once,
               price: 599,
               currency: "usd",
               trialDays: 0,
               active: true,
               priceBefore: 759,
             },
             {
               stripeId: "price_1HKaBqAlf1asoVHVz6HUS4Xn",
               type: PriceType.OneTime,
               billingPeriod: BillingPeriod.Once,
               price: 11399,
               currency: "mxn",
               trialDays: 0,
               active: true,
               priceBefore: 14399,
             },
             {
               stripeId: "price_1HNtYjAlf1asoVHVHGyxkilM",
               type: PriceType.OneTime,
               billingPeriod: BillingPeriod.Once,
               price: 499,
               currency: "eur",
               trialDays: 0,
               active: true,
               priceBefore: 629,
             },
           ],
           features: [
             {
               key: "frontendBackend",
               value: "",
               included: true,
             },
             {
               key: "upToProjects",
               value: "10",
               included: true,
             },
             {
               key: "upToDevelopers",
               value: "10",
               included: true,
             },
             {
               key: "1yearAccess",
               value: "",
               included: true,
             },
             {
               key: "revenue",
               value: ">",
               included: true,
             },
             {
               key: "prioritySupport",
               value: "",
               included: false,
             },
           ],
           badge: "",
           active: true,
           maxUsers: 10,
         },
         {
           stripeId: "prod_HxpEpf69agp8D9",
           tier: 3,
           title: "Ultimate 🦄",
           description: "",
           prices: [
             {
               stripeId: "price_1HNtZgAlf1asoVHVBVbSez4U",
               type: PriceType.Recurring,
               billingPeriod: BillingPeriod.Yearly,
               price: 999,
               currency: "usd",
               trialDays: 0,
               active: true,
               priceBefore: 1259,
             },
             {
               stripeId: "price_1HNtZgAlf1asoVHVSmnlfu9v",
               type: PriceType.Recurring,
               billingPeriod: BillingPeriod.Yearly,
               price: 19999,
               currency: "mxn",
               trialDays: 0,
               active: true,
               priceBefore: 25199,
             },
             {
               stripeId: "price_1HNtZgAlf1asoVHVgxETIe92",
               type: PriceType.Recurring,
               billingPeriod: BillingPeriod.Yearly,
               price: 839,
               currency: "eur",
               trialDays: 0,
               active: true,
               priceBefore: 1059,
             },
           ],
           features: [
             {
               key: "frontendBackend",
               value: "",
               included: true,
             },
             {
               key: "unlimitedProjects",
               value: "",
               included: true,
             },
             {
               key: "unlimitedDevelopers",
               value: "",
               included: true,
             },
             {
               key: "1yearAccess",
               value: "",
               included: true,
             },
             {
               key: "revenue",
               value: ">",
               included: true,
             },
             {
               key: "prioritySupport",
               value: "",
               included: true,
             },
           ],
           badge: "",
           active: true,
           maxUsers: 0,
         },
       ];
