







































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import Stripe from "stripe";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters("tenant", {
      stripeCard: "defaultStripeCard",
      subscription: "subscription",
    }),
  },
})
export default class TenantSubscriptionStatus extends BaseComponent {
  public subscription!: Stripe.Subscription;
  mounted() {
    this.services.stripe.getCurrentCustomer();
  }

  get getEndOfTrial() {
    if (this.subscription) {
      // @ts-ignore
      const date = new Date(this.subscription.trial_end * 1000);

      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(
        Math.abs((date.getTime() - Date.now()) / oneDay)
      );
      return this.$d(date, "short", this.$store.state.locale.locale);
    }

    return "";
  }
  get getEndsIn() {
    if (this.subscription) {
      // @ts-ignore
      const date = new Date(this.subscription.trial_end * 1000);

      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(
        Math.abs((date.getTime() - Date.now()) / oneDay)
      );
      let untilDate: string =
        this.$t("shared.ends") +
        " " +
        this.$t("shared.in") +
        " " +
        diffDays +
        " " +
        this.$t("shared.days");
      if (diffDays === 0) {
        untilDate = this.$t("shared.today").toString();
      } else if (diffDays === 1) {
        untilDate = this.$t("shared.tomorrow").toString();
      }
      return untilDate;
    }

    return "";
  }
}
