






















import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/marketing/Header.vue";
import Hero from "@/components/marketing/Hero.vue";
import Features from "@/components/marketing/Features.vue";
import Newsletter from "@/components/marketing/Newsletter.vue";
import FAQ from "@/components/marketing/FAQ.vue";
import Footer from "@/components/marketing/Footer.vue";

import AppSumoBanner from "@/components/marketing/banners/AppSumoBanner.vue";

@Component({
  components: {
    Header,
    Hero,
    Features,
    Newsletter,
    FAQ,
    Footer,
    AppSumoBanner
  }
})
export default class MarketingProductComponent extends Vue {
  closed = false;
  mounted() {
    this.$store.commit("theme/marketingTheme", "theme-bg-light");
    this.$store.commit("theme/marketingColor", "theme-violet");
  }
}
