

















































































































import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/marketing/Header.vue";

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

@Component({ components: { Header } })
export default class BlogPost1Component extends Vue {
  imgCleanArchitecture = "https://i.ibb.co/W0LcrzM/Clean-Architecture.png";
  imgIntegrationTests = "https://i.ibb.co/DLKjP9V/Integration-Tests.png";
  imgRepositoryPattern = "https://i.ibb.co/8xdGSc9/Repository-Pattern.png";
  imgAppSumoDeal = "https://i.ibb.co/6wpW0KF/NETCORESAAS-v1-3-0-9.png";
}
