


































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ObjectList from "../../../components/shared/forms/ObjectList.vue";
import { report } from "process";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { StripeProduct } from "../../../app/models/subscription/StripeProduct";
import Modal from "../../../components/shared/modals/Modal.vue";
import Products from "../../../components/marketing/pricing/Products.vue";

@Component({
  components: { Products, ObjectList, TableRowSkeleton, Modal }
})
export default class StripeProductComponent extends BaseComponent {
  private items = [] as StripeProduct[];
  private fromServer: boolean = true;
  created() {
    const self = this;
    // this.eventBus.$on("stripeProduct-canceled", () => self.canceled());
    this.eventBus.$on("stripeProduct-added", data => self.added(data));
    this.eventBus.$on("stripeProduct-saved", data => self.saved(data));
    this.eventBus.$on("stripeProduct-deleted", data => self.deleted(data));
  }

  mounted() {
    this.reload(true);
  }
  async reload(fromServer: boolean) {
    this.fromServer = fromServer;
    this.items = [];
    this.loading = true;
    this.services.stripe
      .getProducts(fromServer, false)
      .then((response: any) => {
        this.items = [];
        response?.forEach(product => {
          this.items.push(product);
        });
        if (response.length === 0 && this.fromServer) {
          this.reload(false);
        }
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleted(data: StripeProduct) {
    this.items = this.items.filter(f => f.id !== data.id);
  }
  added(data: StripeProduct) {
    this.items.push(data);
  }
  saved(data: StripeProduct) {
    const idx = this.items.findIndex(f => f.id === data.id);
    this.items[idx] = data;
  }
  async generateFromFiles() {
    await this.items.forEach(product => {
      this.services.stripeAdmin
        .syncProduct(true, product)
        .then(response => {
          this.reload(true);
        })
        .catch(error => {
          // @ts-ignore
          this.$refs["error-modal"].show(error);
        });
    });
  }
  get pricingFile() {
    const debug = process.env.NODE_ENV !== "production";
    if (debug) {
      return "ClientApp/src/store/modules/pricing/default-pricing.Development.ts";
    } else {
      return "ClientApp/src/store/modules/pricing/default-pricing.ts";
    }
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return x?.tier > y?.tier ? 1 : -1;
    });
  }
}
