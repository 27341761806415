


















































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import Steps from "./Steps.vue";

@Component({
  components: { Steps }
})
export default class DashboardComplement extends BaseComponent {}
