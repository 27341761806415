























































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SideModal from "@/components/shared/modals/SideModal.vue";
import VueFormGenerator, { validators } from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";
import { Prop } from "vue-property-decorator";
import dateInput from "@/components/shared/forms/DateInput.vue";
import { StripeProduct } from "../../../app/models/subscription/StripeProduct";
Vue.component("fieldDateInput", dateInput);

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    SideModal,
    "vue-form-generator": VueFormGenerator.component
  }
})
export default class StripeProductFormComponent extends BaseComponent {
  private uuid: any = false;

  private flagAllowEdit: boolean = true;
  private flagAllowDelete: boolean = true;
  private editing: boolean = false;
  private isValid: boolean = false;
  private model = {} as StripeProduct;
  private disabled: boolean = true;
  private updateOnServer: boolean = true;
  private schema: any = {
    fields: [
      {
        id: "title",
        type: "input",
        inputType: "text",
        model: "title",
        label: this.$t("models.stripeProduct.title"),
        placeholder: this.$t("models.stripeProduct.title"),
        required: true,
        validator: validators.string
      },
      {
        id: "description",
        type: "input",
        inputType: "text",
        model: "description",
        label: this.$t("models.stripeProduct.description"),
        placeholder: this.$t("models.stripeProduct.description"),
        required: false,
        validator: validators.string
      },
      {
        id: "stripeId",
        type: "input",
        inputType: "text",
        label: this.$t("models.stripeProduct.stripeId"),
        placeholder: this.$t("models.stripeProduct.stripeIdLeaveEmpty"),
        model: "stripeId",
        required: false,
        attributes: {
          autofocus: true
        },
        validator: validators.string
      },
      {
        id: "tier",
        inputType: "number",
        type: "input",
        model: "tier",
        label: this.$t("models.stripeProduct.tier"),
        placeholder: this.$t("models.stripeProduct.tier"),
        required: true,
        validator: validators.integer
      },
      {
        id: "badge",
        type: "input",
        inputType: "text",
        model: "badge",
        label: this.$t("models.stripeProduct.badge"),
        placeholder: this.$t("models.stripeProduct.badge"),
        required: false,
        validator: validators.string
      },
      {
        id: "active",
        type: "checkbox",
        model: "active",
        label: this.$t("models.stripeProduct.active"),
        required: true
      },
      {
        id: "image",
        type: "image",
        model: "image",
        label: this.$t("models.stripeProduct.image"),
        placeholder: this.$t("models.stripeProduct.image"),
        hideInput: true
      },
      {
        id: "maxUsers",
        inputType: "number",
        type: "input",
        model: "maxUsers",
        label: this.$t("models.stripeProduct.maxUsers"),
        placeholder: this.$t("models.stripeProduct.maxUsers"),
        default: 0
      }
    ]
  };
  private formOptions: any = {
    validateAfterLoad: false,
    validateAfterChanged: true,
    validateAsync: true
  };
  mounted() {
    if (this.$route.params.uuid) {
      this.disabled = true;
      this.uuid = this.$route.params.uuid;
      this.editing = true;

      this.schema.fields[2].disabled = true;
      this.services.stripe
        .getProduct(this.uuid)
        .then((response: any) => {
          this.model = response.data;
          this.disabled = false;
        })
        .catch(error => {
          // @ts-ignore
          this.$refs["error-modal"].show(this.$t("shared.notFound"));
          this.pushToParent();
        });
    } else {
      this.disabled = false;
    }
  }
  cancel() {
    this.eventBus.$emit("stripeProduct-canceled");
    this.pushToParent();
  }
  save() {
    // @ts-ignore
    this.$refs.vfg.validate().then(errors => {
      if (errors && errors.length > 0) {
        // @ts-ignore
        this.$refs["error-modal"].show(this.$t("shared.invalidForm"));
      } else {
        // @ts-ignore
        this.$refs["confirm-save"].show(
          this.editing ? this.$t("shared.save?") : this.$t("shared.add?")
        );
      }
    });
  }
  yesSave() {
    if (this.editing && this.flagAllowEdit) {
      if (this.updateOnServer) {
        this.services.stripeAdmin
          .syncProduct(true, this.model)
          .then(response => {
            this.eventBus.$emit("stripeProduct-saved", this.model);
            this.pushToParent();
          })
          .catch(error => {
            // @ts-ignore
            this.$refs["error-modal"].show(error);
          });
      } else {
        this.eventBus.$emit("stripeProduct-saved", this.model);
        this.pushToParent();
      }
    } else if (!this.editing) {
      if (this.updateOnServer) {
        this.services.stripeAdmin
          .syncProduct(true, this.model)
          .then((response: any) => {
            this.eventBus.$emit("stripeProduct-added", response.data);
            this.pushToParent();
          })
          .catch(error => {
            // @ts-ignore
            this.$refs["error-modal"].show(error);
          });
      } else {
        this.eventBus.$emit("stripeProduct-added", this.model);
        this.pushToParent();
      }
    }
  }
  remove() {
    // @ts-ignore
    this.$refs["confirm-remove"].show(this.$t("shared.remove?"));
  }
  yesRemove() {
    if (this.editing) {
      if (this.updateOnServer) {
        this.services.stripeAdmin
          .deleteProduct(true, this.model.uuid)
          .then(response => {
            this.eventBus.$emit("stripeProduct-deleted", this.model);
          });
      } else {
        this.eventBus.$emit("stripeProduct-deleted", this.model);
      }
    }
  }
}
