





















































































































































































































































import Component from "vue-class-component";
import BaseComponent from "@/components/shared/BaseComponent.vue";
import { Prop } from "vue-property-decorator";
import { Entity, OrderedEntity } from "../../../app/models/Entity";
import { ColumnType, ValueType } from "../../../app/models/ColumnType";
import JsonExcel from "vue-json-excel/JsonExcel.vue";

@Component({
  components: { JsonExcel }
})
export default class EntityTableListComponent extends BaseComponent {
  @Prop()
  public modelName!: string;
  @Prop()
  public items!: Entity[];
  @Prop()
  public columns!: ColumnType[];
  @Prop({ default: true })
  public allowEdit!: boolean;
  @Prop({ default: false })
  public allowAdd!: boolean;
  @Prop({ default: false })
  public allowReload!: boolean;
  @Prop({ default: false })
  public allowDownloadCvs!: boolean;
  @Prop({ default: false })
  public enableDownload!: boolean;
  @Prop({ default: false })
  public enableUpload!: boolean;
  @Prop()
  public routerLinks!: any[];
  @Prop({ default: true })
  public translate!: boolean;
  @Prop({ default: "" })
  public titlePlural!: string;

  private files: FormData = new FormData();
  edit(item: Entity) {
    this.$emit("edit", item);
  }
  reload(item: Entity) {
    this.$emit("reload", item);
  }
  add(item: Entity) {
    this.$emit("add", item);
  }
  downloadFile(item: Entity) {
    this.$emit("downloadFile", item);
  }
  uploadFile(fileList: any, item) {
    this.$emit("uploadFile", item, fileList);
  }
  translateModelName() {
    const translated = this.$t(`models.${this.modelName}.plural`);
    return translated ?? this.modelName;
  }
  translateModelProperty(columnName: string) {
    const translated = this.$t(`models.${this.modelName}.${columnName}`);
    return translated ?? columnName;
  }
  convertedValue(item: Entity, column: ColumnType): string {
    // console.log("column: " + column.name + " " + column.valueType);

    // if (column.valueType === ValueType.Date) {
    //   console.log("item: " + JSON.stringify(item));
    //   console.log("column: " + JSON.stringify(column));
    // }
    if (
      column.valueType === ValueType.TextArea ||
      column.valueType === ValueType.String
    ) {
      const stringValue: string = item[column.name];
      if (stringValue && stringValue.length > 50) {
        return stringValue.padEnd(50).substring(0, 50) + "...";
      }
    }
    return ColumnType.convertValue(item, column);
  }
  convertedArray(item: Entity, column: ColumnType): any {
    if (
      column.valueType === ValueType.TenantUserRoles ||
      column.valueType === ValueType.StripeProducts
    ) {
      const values = ColumnType.getValues(column);
      const descriptions: any = [];
      values.forEach((element: any) => {
        if (item[column.name].includes(element.value)) {
          descriptions.push(element.name);
        }
      });
      const uniqueArray = [...new Set(descriptions)];
      return uniqueArray.join(", ");
    }
  }
  isBoolean(column: ColumnType): boolean {
    return column.valueType === ValueType.Bool;
  }
  isArray(column: ColumnType): boolean {
    return (
      column.valueType === ValueType.TenantUserRoles ||
      column.valueType === ValueType.StripeProducts
    );
  }
  isFile(column: ColumnType): boolean {
    return column.valueType === ValueType.File;
  }
  isImage(column: ColumnType): boolean {
    return column.valueType === ValueType.Image;
  }
  getLinkTo(link: any, item: Entity): string {
    if (link.to.includes("{id}")) {
      return link.to.replace("{id}", item.id);
    } else if (link.to.includes("{uuid}")) {
      return link.to.replace("{uuid}", item.uuid);
    }
    return link.to;
  }
  downloadColumnFile(item: Entity, column: ColumnType) {
    const blob = this.dataURItoBlob(item[column.name]);
    const url = URL.createObjectURL(blob);

    window.open(url, "_blank");
  }
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }
  startDownload() {
    // // @ts-ignore
    // if (!this.$parent.items || this.$parent.items.length === 0) {
    //   // @ts-ignore
    //   this.$refs["error-modal"].show(this.$t("shared.noRecords"));
    // }
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      if (x && y) {
        if (x.hasOwnProperty("order")) {
          // @ts-ignore
          return x.order > y.order ? 1 : -1;
        } else {
          return x.id && y.id && x.id > y.id ? -1 : 1;
        }
      }
      return 1;
    });
  }
}
