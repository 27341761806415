import { authenticationService } from "./master/AuthenticationService";
import { adminService } from "./master/AdminService";
import { tenantService } from "./master/TenantService";
import { tenantUsersService } from "./master/TenantUsersService";
import { userService } from "./master/UserService";
import { resourceService } from "./master/ResourceService";

import { stripeService } from "./subscription/StripeService";
import { stripeAdminService } from "./subscription/StripeAdminService";

import { expenseService } from "./core/ExpenseService";
import { transactionCategoryService } from "./core/TransactionCategoryService";
import { projectService } from "./core/ProjectService";
import { entitiesService } from "./core/EntityService";
import { builderService } from "./core/BuilderService";

class Services {
  authentication = authenticationService;
  admin = adminService;
  tenants = tenantService;
  tenantUsers = tenantUsersService;
  users = userService;
  resources = resourceService;
  stripe = stripeService;
  stripeAdmin = stripeAdminService;
  expenses = expenseService;
  transactionCategories = transactionCategoryService;
  projects = projectService;
  entities = entitiesService;
  builder = builderService;
}

export default new Services();
