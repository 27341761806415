











































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import { ColumnType, ValueType } from "../../../app/models/ColumnType";
import PageTitle from "@/components/shared/navigation/PageTitle.vue";
import EntityTableList from "@/components/shared/entities/EntityTableList.vue";
import EntityForm from "@/components/shared/entities/EntityForm.vue";
import { TenantUserRole } from "../../../app/models/master/account/ITenantDTO";
import { StripeProduct } from "../../../app/models/subscription/StripeProduct";
const fileDownload = require("js-file-download");

@Component({ components: { PageTitle, EntityTableList, EntityForm } })
export default class ResourcesListComponent extends BaseComponent {
  private modelName: string = "resource";
  private columns: ColumnType[] = [
    {
      name: "title"
    },
    {
      name: "description"
    }
    // {
    //   name: "tiers",
    //   valueType: ValueType.StripeProducts,
    // },
    // {
    //   name: "roles",
    //   valueType: ValueType.TenantUserRoles,
    // },
  ];
  private selected = null as any | null;
  private adding: boolean = false;
  private items: any = [];
  mounted() {
    this.items = [];
    this.reload();
  }
  reload() {
    this.loading = true;
    this.services.resources
      .getMyResources()
      .then((response: any) => {
        this.items = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  closeForm(hasChanges = false) {
    this.selected = null;
    this.adding = false;
    if (hasChanges) {
      // @ts-ignore
      this.$parent.hasChanges = true;
    }
  }
  edit(item: any) {
    this.selected = item;
  }
  downloadFile(item: any, column: ColumnType) {
    if (item.needsUpgrade) {
      try {
        const minimumTier = this.products.find(f => f.tier === item.tiers[0]);
        // @ts-ignore
        this.$refs["error-modal"].show(
          this.$t("shared.needsUpgrade") +
            ", " +
            this.$t("shared.atLeast") +
            " " +
            minimumTier?.title
        );
      } catch (e) {
        // @ts-ignore
        this.$refs["error-modal"].show(this.$t("shared.needsUpgrade"));
      }
      return;
    } else if (item.needsPermission) {
      // @ts-ignore
      this.$refs["error-modal"].show(this.$t("shared.needsPermission"));
      return;
    }

    this.services.resources
      .download(item.uuid)
      .then((response: any) => {
        const disposition = response.headers["content-disposition"];
        let fileName = "";

        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const type: string = "application/zip";
        if (item.type.toString().toLowerCase() !== ".zip") {
          fileDownload(response.data, item.title + item.type);
          return;
        } else {
          const fileUrl = window.URL.createObjectURL(
            new Blob([response.data], { type })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", item.title);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  uploadFile(item: any, fileList: any) {
    console.log("uploadFile: " + fileList);
    const files: FormData = new FormData();
    files.append("file", fileList[0], fileList[0].name);
    this.services.resources
      .upload(item.uuid, files)
      .then((response: any) => {
        console.log("uploaded");
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  added(data: any) {
    console.log("added");
    this.services.resources.create(data).then(response => {
      // @ts-ignore
      this.items.push(response.data);
      this.selected = null;
      this.closeForm(true);
    });
  }
  saved(data: any) {
    console.log("saved");
    this.services.resources.update(data.uuid, data).then(response => {
      // @ts-ignore
      const idx = this.items.findIndex(f => f.uuid === data.uuid);
      this.items[idx] = data;
      this.closeForm(true);
    });
  }
  deleted(data: any) {
    console.log("deleted");
    this.services.resources.delete(data.uuid).then(response => {
      // @ts-ignore
      this.items = this.items.filter(f => f.uuid !== data.uuid);
      this.closeForm(true);
    });
  }
  canceled() {
    console.log("canceled");
    this.closeForm();
  }
  get products(): StripeProduct[] {
    return this.$store.state.pricing.products as StripeProduct[];
  }
}
