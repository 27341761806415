import server from "../../plugins/axios-helper";
import store from "@/store";
import { StripeProduct } from "@/app/models/subscription/StripeProduct";
import {
  ITenantDTO,
  ITenantUserDTO,
} from "@/app/models/master/account/ITenantDTO";
import { ITenantJoinSettingsDTO } from "@/app/models/master/account/ITenantJoinSettingsDTO";
import { StripePrice } from "@/app/models/subscription/StripePrice";
import { state } from "@/store/modules/locale";
import { defaultState as defaultTheme } from "@/store/modules/theme";

class TenantService {
  getFromURL() {
    return new Promise((resolve, reject) => {
      server
        .get("tenant/tenantFromURL", {
          masterDatabase: true,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getMyTenants() {
    return new Promise((resolve, reject) => {
      server
        .get("tenant/MyTenants/" + store.state.tenant.current?.apiKey, {
          masterDatabase: true,
        })
        .then((response) => {
          store.commit("tenant/myTenants", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  create(
    name: string,
    stripePrice: StripePrice | undefined,
    stripeCardToken: string
  ) {
    const newTenant = {
      name,
      stripePrice,
      stripeCardToken,
      appTheme: defaultTheme.appTheme,
      appColor: defaultTheme.appColor,
    };
    return new Promise((resolve, reject) => {
      server
        .post("tenant/create", newTenant, { masterDatabase: true })
        .then((response) => {
          store.commit("auth/login", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  changeDefault(apikey: string) {
    return new Promise((resolve, reject) => {
      server
        .post("tenant/changeDefault/" + apikey, null, { masterDatabase: true })
        .then((response) => {
          store.commit("tenant/current", response.data.currentTenant);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  update(payload: ITenantDTO, apiKey?: string) {
    if (!apiKey) {
      apiKey = store.state.tenant.current?.apiKey;
    }
    return new Promise((resolve, reject) => {
      server
        .put("tenant/UpdateTenant/" + apiKey, payload, { masterDatabase: true })
        .then((response) => {
          store.commit("tenant/settings", payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  uploadImage(payload: ITenantDTO, type: string) {
    return new Promise((resolve, reject) => {
      server
        .put(
          "tenant/uploadImage/" +
            store.state.tenant.current?.apiKey +
            "/" +
            type,
          payload,
          { masterDatabase: true }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  delete(apiKey?: string) {
    if (!apiKey) {
      apiKey = store.state.tenant.current?.apiKey;
    }
    return new Promise((resolve, reject) => {
      server
        .delete("tenant/delete/" + apiKey, {
          masterDatabase: true,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export const tenantService = new TenantService();
