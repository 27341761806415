








import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Header from "@/components/marketing/Header.vue";

@Component({
  components: {},
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor",
    }),
  },
})
export default class MarketingComponent extends Vue {
  closed = false;

  mounted() {
    this.showConversations();
  }
  showConversations() {
    // @ts-ignore
    // if (this.$intercom) {
    //   // @ts-ignore
    //   this.$intercom.boot();
    // }

    // @ts-ignore
    if (this.$drift) {
      // @ts-ignore
      // this.$drift.show();
    }
  }
}
