







































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ConfirmModalComponent extends Vue {
  public title!: string;
  public closeText!: any;
  public showing: boolean = false;
  private question!: string;
  private item!: any;
  mounted() {
    this.title = this.$t("shared.confirm").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  public show(question: string, item?: any) {
    this.question = question;
    this.showing = true;
    this.item = item;
    // @ts-ignore
    // this.$refs["close"].focus();
  }
  public yes() {
    this.showing = false;
    this.$emit("yes", this.item);
  }
  public no() {
    this.showing = false;
    this.$emit("no");
  }
}
