import { BuilderCompileRequest } from "@/app/contracts/core/BuilderCompileRequest";
import { BuilderCreateCrudRequest } from "@/app/contracts/core/BuilderCreateCrudRequest";
import { EntityClass } from "@/app/models/core/builder/EntityClass";
import { EntityCrud } from "@/app/models/core/builder/EntityCrud";
import { ApiService, ApiServiceType } from "../ApiService";
import cruds from "./cruds.json";

class BuilderService extends ApiService {
  constructor() {
    super("Builder", ApiServiceType.TenantDatabase);
  }
  getCruds(): Promise<EntityCrud[]> {
    return super.getAll("GetCruds");
  }
  compileCode(data: BuilderCompileRequest): Promise<EntityClass[]> {
    return super.post(data, "CompileCode");
  }
  createCrud(
    data: BuilderCreateCrudRequest,
    projectName: string,
    modelDirectory: string
  ): Promise<any[]> {
    // const fakeCruds: EntityCrud[] = JSON.parse(cruds) as EntityCrud[];
    // return Promise.resolve(cruds);
    return super.post(data, `CreateCrud/${projectName}/${modelDirectory}`);
  }
  createCrudFromClass(data: EntityClass): Promise<EntityCrud[]> {
    return super.post(data, "CreateCrudFromEntityClass");
  }
}
export const builderService = new BuilderService();
