




















import Vue from "vue";
import Component from "vue-class-component";
import SvgLogo from "../../assets/img/logo2.vue";

@Component({
  components: {
    SvgLogo,
  },
})
export default class MarketingLogoComponent extends Vue {}
