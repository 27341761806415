









































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SideModal extends BaseComponent {
  @Prop()
  title!: string;

  close() {
    this.$emit("close");
  }
}
