

























import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import Header from "@/components/marketing/Header.vue";
import Products from "../../components/marketing/pricing/Products.vue";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { StripeProduct } from "../../app/models/subscription/StripeProduct";
import Footer from "@/components/marketing/Footer.vue";
// import Footer from "@/components/marketing/Newsletter.vue";

@Component({
  metaInfo: {
    title: "Pricing"
  },
  components: {
    Header,
    Products,
    TableRowSkeleton,
    // Newsletter,
    Footer
  }
})
export default class PricingComponent extends BaseComponent {
  private closed = false;
  mounted() {
    // @ts-ignore
    if (this.$intercom) {
      // @ts-ignore
      // this.$intercom.show();
    }
  }
}
