










































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";

import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import Stripe from "stripe";
@Component({
  components: { ErrorModal, SuccessModal }
})
export default class TenantSubscriptionInvoices extends BaseComponent {
  upcomingInvoice = {} as Stripe.Invoice;
  created() {
    const self = this;
    this.eventBus.$on("updated-plan", () => self.getUpcomingInvoice());
  }
  beforeDestroy() {
    this.eventBus.$off("updatedPlan");
  }
  mounted() {
    this.getUpcomingInvoice();
  }
  getUpcomingInvoice() {
    const self = this;
    this.services.stripe.getUpcomingInvoice().then((response: any) => {
      self.upcomingInvoice = response.data;
    });
  }
  invoiceDescription(invoice) {
    if (invoice && invoice.created) {
      const created = new Date(invoice.created * 1000);
      const createdString = this.$d(created, "short", this.$i18n.locale);

      return " $" + invoice.amount_due / 100 + " - " + createdString;
    }
    return "";
  }
}
