


























































































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ObjectList from "../../../../components/shared/forms/ObjectList.vue";
import { report } from "process";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { IUserDTO } from "../../../../app/models/master/account/IUserDTO";
import {
  TenantUserRole,
  ITenantUserDTO,
  TenantUserJoined,
  TenantUserStatus
} from "../../../../app/models/master/account/ITenantDTO";
import { Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { StripeProduct } from "../../../../app/models/subscription/StripeProduct";

@Component({
  components: { ObjectList, TableRowSkeleton, ConfirmModal },
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
      memberCount: "memberCount",
      isOwnerOrAdmin: "isOwnerOrAdmin"
    }),
    ...mapGetters("account", {
      email: "email"
    })
  }
})
export default class TenantMembersComponent extends BaseComponent {
  @Prop()
  public propTenantApiKey!: string;
  @Prop({ default: "Users" }) readonly title!: string;

  public tenantApiKey: string = "";
  public items = [] as ITenantUserDTO[];
  private tenantJoinSettings: any = {};
  private enableLink: boolean = false;
  private enablePublicUrl: boolean = false;
  private requireAcceptance: boolean = false;
  private copiedUrlLink: boolean = false;
  private activeProduct!: StripeProduct;
  private memberCount!: number;
  private isOwnerOrAdmin!: boolean;
  private role!: TenantUserRole;
  created() {
    const self = this;
    this.eventBus.$on("user-canceled", () => self.canceled());
    this.eventBus.$on("user-added", data => self.added(data));
    this.eventBus.$on("user-saved", data => self.saved(data));
    this.eventBus.$on("user-deleted", data => self.deleted(data));
  }

  mounted() {
    // SignalService.on("UserAdded", (user: User) => {
    //   this.items.push(user);
    // });
    this.services.tenantUsers.getInvitationSettings().then((response: any) => {
      this.tenantJoinSettings = response.data;
      this.enableLink = this.tenantJoinSettings.linkActive;
      this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
      this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
    });
    this.reload();
  }
  avatarText(user) {
    if (user) {
      if (user.firstName && user.lastName) {
        if (user.firstName.length > 0 && user.lastName.length > 0) {
          return (user.firstName[0] + user.lastName[0]).toUpperCase();
        } else if (user.firstName.length > 1) {
          return user.firstName.substring(0, 2).toUpperCase();
        } else if (user.email.length > 1) {
          return user.email.substring(0, 2).toUpperCase();
        }
      } else {
        return user.email.substring(0, 2).toUpperCase();
      }
    }
  }
  async reload() {
    this.items = [];
    this.loading = true;
    this.services.tenantUsers
      .getUsers(this.tenantApiKey)
      .then((response: any) => {
        response.data.forEach(element => {
          this.items.push(element);
        });
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  canceled() {
    console.log("canceled");
  }
  added(data: ITenantUserDTO) {
    this.reload();
    // console.log("added data:" + JSON.stringify(data));
    // this.items.push(data);
    // // SignalService.invoke(
    //   "AddUser",
    //   this.$store.state.tenant.current.apiKey,
    //   data
    // );
  }
  saved(data) {
    const idx = this.items.findIndex(f => f.id === data.id);
    // console.log("found: " + JSON.stringify(idx));
    this.items[idx] = data;
  }
  deleted(data) {
    this.items = this.items.filter(f => f.id !== data.id);
  }
  updateTenantJoinSettings(reset = false) {
    this.services.tenantUsers
      .updateInvitationSettings({
        enableLink: this.enableLink,
        resetLink: reset,
        enablePublicUrl: this.enablePublicUrl,
        requireAcceptance: this.requireAcceptance
      })
      .then((response: any) => {
        this.tenantJoinSettings = response.data;
        this.enableLink = this.tenantJoinSettings.linkActive;
        this.enablePublicUrl = this.tenantJoinSettings.publicUrl;
        this.requireAcceptance = this.tenantJoinSettings.requireAcceptance;
      });
  }

  changeLinkSettings(e) {
    this.updateTenantJoinSettings();
  }
  copyInviteLink() {
    if (this.maxNumberOfUsersReached) {
      // @ts-ignore
      this.$refs["confirm-upgrade"].show(
        this.$t("settings.tenant.members.maxNumberOfUsers") +
          " (" +
          this.maxNumberOfUsers +
          "). " +
          this.$t("shared.upgrade?")
      );
    } else {
      // @ts-ignore
      this.$clipboard(this.urlLink);
      this.copiedUrlLink = true;
    }
  }
  yesUpdateSubscription() {
    this.$router.push({ path: "/app/settings/organization/subscription" });
  }
  getUserRole(item: ITenantUserDTO) {
    return this.$t("settings.profile.roles." + TenantUserRole[item.role]);
  }
  getUserJoined(item: ITenantUserDTO) {
    if (item.status === TenantUserStatus.Active) {
      return this.$t(
        "settings.profile.joined." + TenantUserJoined[item.joined]
      );
    }
  }
  getUserStatus(item: ITenantUserDTO) {
    return this.$t("settings.profile.status." + TenantUserStatus[item.status]);
  }
  getUserRoleClass(item: ITenantUserDTO) {
    switch (item.role as TenantUserRole) {
      case TenantUserRole.Owner:
        return "bg-gray-800 text-gray-100";
      case TenantUserRole.Admin:
        return "bg-orange-200";
      case TenantUserRole.Member:
        return "bg-blue-200";
      case TenantUserRole.Guest:
        return "bg-gray-200";
    }
  }
  getUserJoinedClass(item: ITenantUserDTO) {
    if (item.status === TenantUserStatus.Active) {
      switch (item.joined as TenantUserJoined) {
        case TenantUserJoined.Creator:
          return "bg-gray-800 text-gray-100";
        case TenantUserJoined.JoinedByInvitation:
          return "bg-blue-200";
        case TenantUserJoined.JoinedByLink:
          return "bg-teal-200";
        case TenantUserJoined.JoinedByPublicUrl:
          return "bg-orange-200";
      }
    }
  }
  getUserStatusClass(item: ITenantUserDTO) {
    switch (item.status as TenantUserStatus) {
      case TenantUserStatus.PendingInvitation:
        return "bg-yellow-200";
      case TenantUserStatus.PendingAcceptance:
        return "bg-yellow-200";
      case TenantUserStatus.Active:
        return "bg-teal-200";
      case TenantUserStatus.Inactive:
        return "bg-red-200";
    }
  }
  pendingAcceptance(item: ITenantUserDTO) {
    return item.status === TenantUserStatus.PendingAcceptance;
  }
  acceptUser(item: ITenantUserDTO, accept: boolean) {
    if (this.isOwnerOrAdmin) {
      item.accepted = accept;
      this.services.tenantUsers
        .acceptUser(item)
        .then(response => {
          this.reload();
        })
        .catch(error => {
          // @ts-ignore
          this.$refs["error-modal"].show(error);
        });
    }
  }
  get maxNumberOfUsers(): number {
    return this.activeProduct && this.activeProduct.maxUsers
      ? this.activeProduct.maxUsers
      : 0;
  }
  get maxNumberOfUsersReached() {
    // @ts-ignore
    if (
      this.maxNumberOfUsers > 0 &&
      this.memberCount >= this.maxNumberOfUsers
    ) {
      return true;
    }
    return false;
  }

  get urlLink() {
    if (this.tenantJoinSettings) {
      return location.origin + "/invite/" + this.tenantJoinSettings.link;
    }
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return x.role > y.role ? -1 : 1;
    });
  }
}
