// import { appPermissionsRoutes } from "./appPermissionsRoutes";
import { appSettingsRoutes } from "./appSettingsRoutes";
// import { appIntegrationsRoutes } from "./appIntegrationsRoutes";
import { i18n } from "@/plugins/vue-i18n";
import { support } from "./support";

export const appRoutes = {
  path: "/app",
  component: require("@/views/app/Index.vue").default,
  meta: {
    requiresAuth: true,
  },
  redirect: "get-started",
  children: [
    {
      path: "get-started",
      component: require("@/views/app/core/get-started/Index.vue").default,
      meta: {
        breadcrumb: [{ name: i18n.t("app.core.get-started.title") }],
      },
    },
    {
      path: "projects",
      component: require("@/views/app/core/projects/Index.vue").default,
      meta: {
        breadcrumb: [{ name: i18n.t("app.core.projects.title") }],
      },
    },
    {
      path: "projects/generator/:uuid",
      meta: {
        breadcrumb: [{ name: i18n.t("app.core.projects.generator") }],
      },
      component: require("@/views/app/core/projects/Project.vue").default,
    },
    // {
    //   path: "builder",
    //   meta: {
    //     breadcrumb: [{ name: i18n.t("app.core.builder.title") }],
    //   },
    //   component: require("@/views/app/core/builder/Index.vue").default,
    // },
    {
      path: "releases",
      component: require("@/views/app/core/versions/Releases.vue").default,
      meta: {
        requiresAuth: true,
        breadcrumb: [{ name: i18n.t("app.core.releases.title") }],
      },
    },
    // support,
    {
      path: "expenses",
      component: require("@/views/app/transactions/Expenses.vue").default,
      meta: {
        requiresAuth: true,
        title: "Expenses",
      },
      children: [
        {
          path: "new",
          component: require("@/views/app/transactions/ExpensesForm.vue")
            .default,
        },
        {
          path: ":id/edit",
          component: require("@/views/app/transactions/ExpensesForm.vue")
            .default,
        },
      ],
    },
    {
      path: "resources",
      component: require("@/views/app/resources/MyResources.vue").default,
      meta: {
        title: i18n.t("models.resource.plural"),
      },
    },
    {
      path: "unauthorized",
      component: require("@/views/app/common/Unauthorized.vue").default,
      meta: {
        title: i18n.t("shared.unauthorized"),
      },
    },
    // appPermissionsRoutes,
    appSettingsRoutes,
    // appIntegrationsRoutes,
  ],
};
