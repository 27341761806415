




















































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { ITenantDTO } from "../../app/models/master/account/ITenantDTO";
import { mapGetters } from "vuex";
import { defaultState } from "../../store/modules/theme";
import { IVerifyDTO } from "../../app/models/master/account/IVerifyDTO";

@Component({
  components: { ErrorModal, MarketingLogo },
  directives: {
    GoogleSignInButton,
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor",
    }),
  },
})
export default class InvitationComponent extends BaseComponent {
  public requirePassword: boolean = true;
  public user = {} as IVerifyDTO;
  public invitation = {} as any;
  public tenant = {} as ITenantDTO;
  private created() {
    this.user.token = this.$route.query.i ? this.$route.query.i.toString() : "";
    this.user.email = this.$route.query.e ? this.$route.query.e.toString() : "";
  }
  private mounted() {
    if (this.$route.query.i) {
      this.loading = true;
      this.$store.commit("theme/marketingTheme", defaultState.marketingTheme);
      this.$store.commit("theme/marketingColor", defaultState.marketingColor);
      this.services.tenantUsers
        .getInvitation(this.$route.query.i.toString())
        .then((response: any) => {
          this.invitation = response.data.invitation;
          this.tenant = response.data.tenant;
          this.requirePassword = response.data.requiresVerify;

          if (this.tenant.appTheme) {
            this.$store.commit("theme/marketingTheme", this.tenant.appTheme);
          }
          if (this.tenant.appColor) {
            this.$store.commit("theme/marketingColor", this.tenant.appColor);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  private accept() {
    if (this.user.password !== this.user.passwordConfirm) {
      return;
    }

    // @ts-ignore
    this.$refs.loadingButton.start();
    this.services.tenantUsers
      .acceptInvitation(this.user)
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
      });
  }
  get tenantTheme() {
    if (this.tenant) {
      return this.tenant.appTheme + " " + this.tenant.appColor;
    }
  }
}
