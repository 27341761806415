

































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import Stripe from "stripe";
import { Card, createToken } from "vue-stripe-elements-plus";
import { mapGetters } from "vuex";

@Component({
  components: { SuccessModal, ErrorModal, Card },
  computed: {
    ...mapGetters("tenant", {
      stripeCard: "defaultStripeCard"
    })
  }
})
export default class TenantSubscriptionPaymentDetails extends BaseComponent {
  private editing: boolean = false;
  private stripeKey!: string;
  private stripeOptions!: any;
  private complete: boolean = false;
  private stripeCard!: Stripe.Card;
  created() {
    // @ts-ignore
    this.stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY.toString();
    this.stripeOptions = {
      showCardHolderName: true,
      hidePostalCode: false,
      iconStyle: "solid",
      elements: {
        locale: this.$i18n.locale
      }
    };
  }
  updatePaymentDetails() {
    // @ts-ignore
    this.$refs.loadingButton.start();
    createToken()
      .then(data => {
        if (data.error) {
          // @ts-ignore
          this.$refs["error-modal"].show(data.error.message);
        } else {
          if (data.token.id && data.token.id !== "") {
            this.updateCard(data.token.id);
          }
        }
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
        // @ts-ignore
        this.$refs.loadingButton.stop();
        this.editing = false;
      });
  }
  updateCard(token: string) {
    this.services.stripe
      .updateCard(token)
      .then(response => {
        this.services.stripe.getCurrentCustomer();
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.tenant.payment.updated")
        );
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
        this.editing = false;
      });
  }

  get stripeCardLast4() {
    return this.stripeCard?.last4;
  }
  get stripeCardHolder() {
    return this.stripeCard?.name;
  }
  get stripeCardExpDesc() {
    return (
      ("0" + this.stripeCard?.exp_month).slice(-2) +
      " / " +
      this.stripeCard?.exp_year
    );
  }
}
