var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    '': _vm.size === 'sm',
    'text-md': _vm.size === 'md',
    'text-lg': _vm.size === 'lg',
    'text-2xl': _vm.size === '2xl',
  }},[_c('div',[_c('div',{staticClass:"sm:hidden w-32"},[_c('label',{staticClass:"sr-only",attrs:{"for":"tabs"}},[_vm._v(_vm._s(_vm.$t("shared.select")))]),_c('select',{staticClass:"block w-full btn-lightgray ",class:{
          'h-8': _vm.size === 'sm',
          'text-md h-10 justify-center': _vm.size === 'md',
          'text-lg h-10 justify-center': _vm.size === 'lg',
          'text-2xl h-10 justify-center': _vm.size === '2xl',
        },attrs:{"id":"tabs","name":"tabs"}},_vm._l((_vm.items),function(item,index){return _c('option',{key:index,domProps:{"selected":_vm.selected === index}},[_vm._v(_vm._s(_vm.prefix)+_vm._s(item[_vm.titleProperty])+_vm._s(_vm.sufix))])}),0)]),_c('div',{staticClass:"hidden sm:block"},[_c('nav',{staticClass:"flex space-x-4",attrs:{"aria-label":"Tabs"}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('div',[_c('button',{class:{
                'btn-theme': _vm.selected === index,
                'btn-lightgray': _vm.selected !== index,
                'text-md p': _vm.size === 'md',
                'text-lg': _vm.size === 'lg',
                'text-2xl': _vm.size === '2xl',
              },on:{"click":function($event){return _vm.selectTab(index)}}},[_vm._v(" "+_vm._s(_vm.prefix)+_vm._s(item[_vm.titleProperty])+_vm._s(_vm.sufix)+" ")])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }