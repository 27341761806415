



































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";

@Component({
  components: { SuccessModal, ErrorModal }
})
export default class NewsletterComponent extends BaseComponent {
  private subscribeURL: string = "";
  private email: string = "";
  mounted() {
    // @ts-ignore
    this.subscribeURL = process.env.VUE_APP_INTEGRATIONS_NEWSLETTER_MAILCHIMP;
  }
  subscribe() {
    if (this.subscribeURL) {
      window.open(this.subscribeURL + "&EMAIL=" + this.email, "_blank");
      // // @ts-ignore
      // this.$refs["success-modal"].show();
    }
  }
}
