


































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import EntityTableList from "@/components/shared/entities/EntityTableList.vue";
import EntityForm from "@/components/shared/entities/EntityForm.vue";
import { ColumnType, ValueType } from "../../../app/models/ColumnType";
import { columns } from "../../../app/models/subscription/StripePrice";

@Component({
  components: { EntityTableList, EntityForm }
})
export default class PricesComponent extends BaseComponent {
  modelName: string = "stripePrice";
  columns: ColumnType[] = columns;
  selected = null as any | null;
  adding: boolean = false;
  created() {
    this.eventBus.$on(this.modelName + "-added", data => {
      // @ts-ignore
      this.$parent.item.prices.push(data);
      this.selected = null;
      this.closeForm(true);
    });
    this.eventBus.$on(this.modelName + "-saved", data => {
      // @ts-ignore
      const idx = this.items.findIndex(f => f.id === data.id);
      this.items[idx] = data;
      this.closeForm(true);
    });
    this.eventBus.$on(this.modelName + "-deleted", data => {
      // @ts-ignore
      this.$parent.item.prices = this.items.filter(f => f.id !== data.id);
      this.closeForm(true);
    });
    this.eventBus.$on(this.modelName + "-canceled", data => {
      console.log("canceled");
      this.closeForm();
    });
  }
  closeForm(hasChanges = false) {
    this.selected = null;
    this.adding = false;
    if (hasChanges) {
      // @ts-ignore
      this.$parent.hasChanges = true;
    }
  }
  edit(item: any) {
    this.selected = item;
  }
  get items() {
    // @ts-ignore
    if (this.$parent.item) {
      // @ts-ignore
      return this.$parent.item.prices;
    }
  }
}
