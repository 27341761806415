




















































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import LoadingButton from "../../components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";

import SvgIcon from "../../assets/img/icon.vue";
@Component({
  metaInfo: {
    title: "Forgot password?"
  },
  components: {
    SuccessModal,
    ErrorModal,
    MarketingLogo,
    LoadingButton,
    SvgIcon
  }
})
export default class ForgotComponent extends BaseComponent {
  private emailSent: boolean = false;
  private email: string = "";
  private verifyToken: string = "";
  created() {
    this.email = this.$route.query.e ? this.$route.query.e.toString() : "";
  }
  reset() {
    // @ts-ignore
    this.$refs.loadingButton.start();
    this.services.authentication
      .reset(this.email)
      .then((response: any) => {
        this.emailSent = true;
        this.verifyToken = response.data.verifyToken;
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore
        this.$refs.loadingButton.stop();
      });
  }

  get verifyURL() {
    if (this.verifyToken) {
      return "/account/verify?e=" + this.email + "&t=" + this.verifyToken;
    }
    return "";
  }
}
