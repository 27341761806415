


































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class SuccessModalComponent extends Vue {
  private title!: string;
  private closeText!: any;
  private showing: boolean = false;
  private successDescription!: string;
  mounted() {
    this.title = this.$t("shared.success").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  public show(description: string) {
    this.successDescription = description;
    this.showing = true;
    // @ts-ignore
    // this.$refs["close"].focus();
  }
}
