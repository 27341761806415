


























































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import Selector from "@/components/shared/forms/Selector.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import supportedLocales from "../../../locale/supportedLocales";
import UploadImage from "@/components/shared/forms/UploadImage.vue";
import {
  IUserDTO,
  LoginType
} from "../../../app/models/master/account/IUserDTO";

@Component({
  components: { Selector, SuccessModal, ErrorModal, UploadImage, ConfirmModal }
})
export default class ProfileComponent extends BaseComponent {
  private profile = {} as IUserDTO;
  private selectedLocale: string = "";
  private locales: string[] = [];
  private showUploadImage = false;
  created() {
    this.profile.email = this.$store.state.account.user.email;
    this.profile.firstName = this.$store.state.account.user.firstName;
    this.profile.lastName = this.$store.state.account.user.lastName;
    this.profile.phone = this.$store.state.account.user.phone;
    this.profile.loginType = this.$store.state.account.user.loginType;
  }
  mounted() {
    this.selectedLocale = this.$i18n.locale;
    supportedLocales.forEach(f => {
      this.locales.push(f.lang);
    });
  }
  canChangePassword() {
    if (this.profile.loginType === LoginType.Password) {
      return true;
    }
    return false;
  }
  changedLocale(value) {
    console.log("changed locale :" + value);
    this.$store.commit("locale/updateLocale", value);
    this.$i18n.locale = value;
    this.$router.go(0);
  }
  updateProfile() {
    this.services.users
      .updateProfile(this.profile)
      .then(response => {
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.profile.profileUpdated")
        );
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  logout() {
    this.$store.dispatch("auth/logout");
  }
  updatePassword() {
    this.services.users
      .updatePassword(this.profile)
      .then(response => {
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.profile.passwordUpdated")
        );
        this.profile.passwordCurrent = "";
        this.profile.passwordNew = "";
        this.profile.passwordConfirm = "";
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  deleteAccount() {
    // @ts-ignore
    this.$refs["confirm-modal"].show(this.$t("settings.danger.confirmDelete"));
  }
  confirmDelete() {
    this.services.users
      .deleteAccount(this.profile.email)
      .then(response => {
        // ignore
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  loadedImage(image) {
    this.services.users
      .uploadAvatar(image)
      .then(response => {
        this.showUploadImage = false;
      })
      .catch(error => {
        console.error("Error: " + error);
      });
  }

  get avatar() {
    return this.$store.state.account.user.avatar;
  }
}
