






















































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import { StripeProduct } from "../../app/models/subscription/StripeProduct";
import Stripe from "stripe";
import { Emit } from "vue-property-decorator";
import BillingPeriodToggle from "../../components/marketing/toggles/BillingPeriodToggle.vue";
import { mapGetters } from "vuex";
import {
  StripePrice,
  BillingPeriod
} from "../../app/models/subscription/StripePrice";

@Component({
  components: { BillingPeriodToggle },
  computed: {
    ...mapGetters("pricing", {
      selectedProductName: "selectedProductName",
      selectedPrice: "selectedPrice",
      selectedBillingPeriod: "selectedBillingPeriod"
    })
  }
})
export default class PlansComponent extends BaseComponent {
  public droppedDown: boolean = false;
  created() {
    if (this.$store.state.pricing.products.length === 0) {
      this.services.stripe.getProducts(false, true);
    }
  }
  // mounted() {
  //   this.setFromCurrentSubscription();
  // }
  // public setFromCurrentSubscription() {
  //   if (this.subscription) {
  //   }
  // }
  private changeProduct(product, currentPrice) {
    this.closeDropdown();
    this.$store.commit("pricing/select", {
      product,
      billingPeriod: currentPrice.billingPeriod
    });
  }
  private closeDropdown() {
    this.droppedDown = false;
  }
  private price(product: StripeProduct) {
    return (
      product.prices.find(
        f =>
          f.billingPeriod === this.$store.state.pricing.billingPeriod &&
          f.price > 0
      ) ?? product.prices[0]
    );
  }
  private billingPeriod(price: StripePrice) {
    if (price.billingPeriod === BillingPeriod.Once) {
      return this.$t("marketing.pricing.once");
    } else {
      return (
        "/ " +
        this.$t(
          "marketing.pricing." + BillingPeriod[price.billingPeriod] + "Short"
        )
      );
    }
  }
  private isSelected(product: StripeProduct, currentPrice: StripePrice) {
    if (this.selectedProduct?.title === product.title) {
      if (
        currentPrice.billingPeriod === BillingPeriod.Once ||
        (this.$store.state.pricing.billingPeriod ===
          currentPrice.billingPeriod &&
          currentPrice.currency === this.$store.state.pricing.currency)
      ) {
        return true;
      }
    }
    return false;
  }
  private prices(product: StripeProduct) {
    return product.prices.filter(
      f => f.currency === this.$store.state.pricing.currency && f.active
    );
  }
  get products() {
    return (this.$store.state.pricing.products as StripeProduct[])
      ?.filter(f => f.active)
      .sort((x, y) => {
        return x.tier > y.tier ? 1 : -1;
      });
  }
  get subscription() {
    return this.$store.state.tenant.stripeSubscription as Stripe.Subscription;
  }
  get selectedProduct(): StripeProduct | undefined {
    return this.$store.state.pricing.selectedProduct as StripeProduct;
  }
}
