









































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import {
  BillingPeriod,
  StripePrice,
} from "../../../app/models/subscription/StripePrice";
import { StripeProduct } from "../../../app/models/subscription/StripeProduct";
@Component
export default class BillingPeriodToggleComponent extends BaseComponent {
  private changeInterval(billingPeriod: BillingPeriod) {
    this.$store.commit("pricing/billingPeriod", billingPeriod);
  }
  private changeToMonthly() {
    this.changeInterval(BillingPeriod.Monthly);
  }
  private changeToYearly() {
    this.changeInterval(BillingPeriod.Yearly);
  }
  private billingPeriodName(billingPeriod: BillingPeriod): string {
    return BillingPeriod[billingPeriod];
  }
  private isYearly(billingPeriod: BillingPeriod): boolean {
    return billingPeriod === BillingPeriod.Yearly;
  }
  private yearlyDiscount(billingPeriod: BillingPeriod): string | undefined {
    const priceYearly = this.getPriceWithInterval(BillingPeriod.Yearly);
    const priceMonthly = this.getPriceWithInterval(BillingPeriod.Monthly);
    if (priceYearly && priceMonthly) {
      const discount =
        100 - (priceYearly.price * 100) / (priceMonthly.price * 12);
      if (discount !== 0) {
        return "-" + discount.toFixed(0) + "%";
      }
    }

    return undefined;
  }
  private getPriceWithInterval(
    billingPeriod: BillingPeriod
  ): StripePrice | undefined {
    let price: StripePrice | undefined;
    if (this.products && this.products.length > 0) {
      this.products.forEach((product) => {
        const prices = product.prices.find(
          (f) =>
            f.billingPeriod === billingPeriod &&
            f.currency === this.$store.state.pricing.currency
        );
        if (prices) {
          price = prices;
        }
      });
    }
    return price;
  }
  get products(): StripeProduct[] {
    return this.$store.state.pricing.products as StripeProduct[];
  }
  get billingPeriods() {
    const allBillingPeriods: BillingPeriod[] = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    this.products.forEach((element) => {
      element.prices.forEach((price) => {
        if (
          this.$store.state.pricing.currency === price.currency &&
          price.billingPeriod !== BillingPeriod.Once
        ) {
          allBillingPeriods.push(price.billingPeriod);
        }
      });
    });
    return allBillingPeriods.filter(onlyUnique);
  }
  get billingPeriod() {
    return this.$store.state.pricing.billingPeriod as BillingPeriod;
  }
  get isMonthly() {
    return this.billingPeriod !== BillingPeriod.Monthly;
  }
}
