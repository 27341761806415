


























import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import {
  BillingPeriod,
  StripePrice,
} from "../../../app/models/subscription/StripePrice";
import { defaultProducts } from "../../../store/modules/pricing/default-pricing";
import { defaultProductsDevelopment } from "../../../store/modules/pricing/default-pricing.Development";
import { StripeProduct } from "../../../app/models/subscription/StripeProduct";

@Component
export default class BillingPeriodToggleComponent extends BaseComponent {
  private changeCurrency(currency) {
    this.$store.commit("pricing/currency", currency);
  }
  get products(): StripeProduct[] {
    return this.$store.state.pricing.products as StripeProduct[];
  }
  get currencies() {
    const allCurrencies: string[] = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    this.products.forEach((element) => {
      element.prices.forEach((price) => {
        if (
          this.$store.state.pricing.billingPeriod === price.billingPeriod ||
          true
        ) {
          allCurrencies.push(price.currency.toLowerCase());
        }
      });
    });
    return allCurrencies.filter(onlyUnique);
  }
}
