












import { mapGetters } from "vuex";
import TopBanner from "@/components/marketing/banners/TopBanner.vue";

export default {
    components: { TopBanner },
    computed: {
        ...mapGetters("theme", {
            marketingTheme: "marketingTheme",
            marketingColor: "marketingColor"
        })
    },
    data: () => {
        return {
            closed: false
        };
    },
    name: "App",
    metaInfo: {
        title: "Boilerplate",
        titleTemplate: "%s | NetcoreSaas"
    },
};
