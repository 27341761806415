



























































































import Vue from "vue";
import Component from "vue-class-component";
import Header from "@/components/marketing/Header.vue";
import Footer from "@/components/marketing/Footer.vue";
import { blogService } from "../../services/marketing/BlogService";

@Component({ components: { Header, Footer } })
export default class BlogComponent extends Vue {
  private subscribeURL: string = "";
  private email: string = "";
  mounted() {
    // @ts-ignore
    this.subscribeURL = process.env.VUE_APP_INTEGRATIONS_NEWSLETTER_MAILCHIMP;
  }
  subscribe() {
    if (this.subscribeURL) {
      window.open(this.subscribeURL + "&EMAIL=" + this.email, "_blank");
    }
  }
}
