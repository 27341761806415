











import Vue from "vue";
import Component from "vue-class-component";
import { adminService } from "../../services/master/AdminService";
import { IUserDTO } from "../../app/models/master/account/IUserDTO";
import TenantMembers from "@/components/app/settings/tenant/TenantMembers.vue";
@Component({ components: { TenantMembers } })
export default class UsersComponent extends Vue {
  private users?: IUserDTO[] = [];
  private tenantName: string = "";
  private tenantApiKey: string = "";
  mounted() {
    this.tenantName = this.$route.params.tenant.toString();
    this.tenantApiKey = this.$route.params.apiKey.toString();
  }
}
