


































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { JsonHubProtocol } from "@aspnet/signalr";

@Component
export default class ErrorModalComponent extends Vue {
  private title!: string;
  private closeText!: any;
  private showing: boolean = false;
  private errorDescription!: string;
  mounted() {
    this.title = this.$t("shared.error").toString();
    this.closeText = this.$t("shared.close").toString();
  }
  public show(error: string, title: string = "") {
    if (error.length > 300 && process.env.NODE_ENV !== "production") {
      error = error.substring(0, 300);
    }
    if (title && title !== "") {
      this.title = title;
    }
    this.errorDescription = error;
    this.showing = true;
    // @ts-ignore
    // this.$refs["close"].focus();
  }
}
