import VueI18n from "vue-i18n";
import { i18n } from "../../plugins/vue-i18n";
import { UserType } from "../../app/models/master/account/IUserDTO";
import { TenantUserRole } from "../../app/models/master/account/ITenantDTO";

export interface SideBarItem {
  title: string | VueI18n.TranslateResult;
  path?: string;
  icon?: string;
  open?: boolean;
  userTypes?: UserType[];
  userRoles?: TenantUserRole[];
  items?: SideBarItem[];
}
export const appSidebar: SideBarItem[] = [
  {
    title: i18n.t("app.sidebar.manage"),
    items: [
      {
        title: i18n.t("app.core.projects.title"),
        path: "/app/projects",
        // icon: "folder",
      },
      //  {
      //    title: i18n.t("app.core.get-started.title"),
      //    path: "/app/get-started",
      //    icon: "download",
      //  },
      {
        title: i18n.t("app.core.releases.title"),
        path: "/app/releases",
        // icon: "code-branch",
      },
      // {
      //   title: i18n.t("app.core.builder.title"),
      //   path: "/app/builder",
      //   icon: "code",
      // },
      // {
      //   title: i18n.t("app.core.support.title"),
      //   path: "/app/support",
      //   icon: "question-circle",
      //   items: [
      //     {
      //       title: i18n.t("app.core.support.roadmap"),
      //       path: "/app/support/roadmap",
      //       icon: "map-signs",
      //     },
      //     {
      //       title: i18n.t("app.core.support.issues"),
      //       path: "/app/support/issues",
      //       icon: "bug",
      //     },
      //     {
      //       title: i18n.t("app.core.support.community"),
      //       path: "/app/support/community",
      //       icon: "hashtag",
      //     },
      //     {
      //       title: i18n.t("app.core.support.chat"),
      //       path: "/app/support/chat",
      //       icon: "comment-dots",
      //     },
      //   ],
      // },
    ],
  },
  {
    title: i18n.t("app.navbar.settings"),
    items: [
      // {
      //   title: i18n.t("app.navbar.tenant"),
      //   path: "/app/settings/organization",
      //   children: [
      {
        title: i18n.t("app.navbar.tenant"),
        path: "/app/settings/organization/general",
      },
      {
        title: i18n.t("settings.tenant.subscription.title"),
        path: "/app/settings/organization/subscription",
      },
      // {
      //   title: i18n.t("app.navbar.profile"),
      //   path: "/app/settings/profile",
      // },
      {
        title: i18n.t("settings.tenant.members.title"),
        path: "/app/settings/organization/members",
      },
    ],
  },
  {
    title: i18n.t("admin.title"),
    items: [
      {
        title: i18n.t("admin.switchToAdmin"),
        path: "/admin",
        // icon: "user-shield",
        userTypes: [UserType.Admin],
      },
    ],
  },
];
