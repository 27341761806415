<template>
  <div>
    <Header></Header>
    <section class="text-primary body-font relative min-h-screen">
      <FAQ :fullPage="true"></FAQ>
    </section>
    <Footer></Footer>

    <success-modal
      ref="success-modal"
      :title="$t('marketing.contact.sentTitle')"
      :text="$t('marketing.contact.sentMessage')"
      :close="$t('marketing.contact.close')"
    ></success-modal>
    <error-modal
      ref="error-modal"
      :title="$t('shared.error')"
      :text="$t('marketing.newsletter.errorText')"
      :closeText="$t('shared.close')"
    ></error-modal>
  </div>
</template>

<script>
import Header from "@/components/marketing/Header.vue";
import Footer from "@/components/marketing/Footer.vue";
import FAQ from "@/components/marketing/FAQ.vue";

export default {
  metaInfo: {
    title: "FAQ"
  },
  components: {
    Header,
    Footer,
    FAQ
  },
  data() {
    return {};
  }
};
</script>
