import server from "../../plugins/axios-helper";
import store from "@/store";
import { IRegisterDTO } from "@/app/models/master/account/IRegisterDTO";
import { ILoginDTO } from "@/app/models/master/account/ILoginDTO";
import { LoginType } from "@/app/models/master/account/IUserDTO";
import { IVerifyDTO } from "@/app/models/master/account/IVerifyDTO";

class AuthenticationService {
  login(payload: ILoginDTO) {
    return new Promise((resolve, reject) => {
      server
        .post("authentication/login", payload, { masterDatabase: true })
        .then((response) => {
          store.commit("auth/login", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  register(payload: IRegisterDTO) {
    return new Promise((resolve, reject) => {
      server
        .post("authentication/register", payload, { masterDatabase: true })
        .then((response) => {
          if (payload.loginType !== LoginType.Password) {
            store.commit("auth/login", response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  appSumoRedeem(payload: IRegisterDTO) {
    return new Promise((resolve, reject) => {
      server
        .post("appsumo/redeem", payload, {
          masterDatabase: true,
        })
        .then((response) => {
          store.commit("auth/login", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  verify(payload: IVerifyDTO) {
    return new Promise((resolve, reject) => {
      server
        .post("authentication/verify", payload, { masterDatabase: true })
        .then((response) => {
          store.commit("auth/login", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  reset(email: string) {
    return new Promise((resolve, reject) => {
      server
        .post("authentication/reset/" + email, {
          masterDatabase: true,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export const authenticationService = new AuthenticationService();
