





























































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import Header from "@/components/marketing/Header.vue";
import Footer from "@/components/marketing/Footer.vue";
import ProjectGenerator from "@/components/app/core/projects/ProjectGenerator.vue";

@Component({
  components: {
    Header,
    ProjectGenerator,
    Footer
  }
})
export default class GeneratorComponent extends BaseComponent {
  mounted() {
    // @ts-ignore
    this.$refs.generator.loadVariables();
    this.$store.commit("project/organization", "");
    this.$store.commit("project/name", "");
    this.$store.commit("project/author", "");
    this.$store.commit("project/url", "");
    this.$store.commit("project/creating", false);
  }
  saveProject() {
    if (!this.validateFormVariables(true)) {
      return;
    }
    // @ts-ignore
    const organization = this.$refs.generator.getVariableValue(
      "ORGANIZATION_NAME"
    );
    // @ts-ignore
    const project = this.$refs.generator.getVariableValue("PRODUCT_NAME");
    // @ts-ignore
    const author = this.$refs.generator.getVariableValue("AUTHOR_NAME");
    // @ts-ignore
    const url = this.$refs.generator.getVariableValue("PRODUCT_URL");

    this.$store.commit("project/organization", organization);
    this.$store.commit("project/name", project);
    this.$store.commit("project/author", author);
    this.$store.commit("project/url", url);
    this.$store.commit("project/creating", true);
    if (this.$store.state.auth.authenticated) {
      this.$router.push("/app/projects");
    } else {
      this.$router.push("/pricing");
    }
  }
  validateFormVariables(showErrors: boolean = true) {
    // @ts-ignore
    return this.$refs.generator.validateFormVariables(showErrors);
  }
  get selectedRelease() {
    try {
      // @ts-ignore
      return this.$refs.generator.selectedRelease;
    } catch (e) {
      // ignore
    }
  }
}
