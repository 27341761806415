


























































































































































































































































































import Vue from "vue";
import { VueTyper } from "vue-typer";
import Component from "vue-class-component";
import SvgLogo from "../../assets/img/logo.vue";
import ColorSwitcher from "./selectors/ColorSwitcher.vue";
import VueLazyload from "vue-lazyload";

const imgPostmark =
  "https://yahooder.sirv.com/netcoresaas/logos/jpg/postmark.jpg";
const imgTailwind =
  "https://yahooder.sirv.com/netcoresaas/logos/jpg/tailwind.jpg";
const imgStripe = "https://yahooder.sirv.com/netcoresaas/logos/jpg/stripe.jpg";
const imgNet = "https://yahooder.sirv.com/netcoresaas/logos/jpg/net.jpg";
const imgVue = "https://yahooder.sirv.com/netcoresaas/logos/jpg/vue.jpg";
const imgLogo3 = "https://yahooder.sirv.com/netcoresaas/logos/jpg/logo3.jpg";

Vue.use(VueLazyload, {
  // set observer to true
  observer: true,
  // optional
  observerOptions: {
    rootMargin: "0px",
    threshold: 0.1
  }
});

@Component({ components: { VueTyper, SvgLogo, ColorSwitcher } })
export default class HeroComponent extends Vue {
  private imgPostmark = imgPostmark;
  private imgStripe = imgStripe;
  private imgNet = imgNet;
  private imgTailwind = imgTailwind;
  private imgVue = imgVue;
  private imgLogo3 = imgLogo3;
  private email: string = "";
  private imgCoverLight: string = "https://i.ibb.co/wc80JMc/3.png";
  private imgCoverDark: string = "https://i.ibb.co/0G38BLx/2.png";
  register() {
    this.$router.push({ path: "pricing?e=" + this.email });
  }
}
