import { Module } from "vuex";
import { ProjectState, RootState } from "@/types/stateTypes";

export const state: ProjectState = {
  organization: "",
  name: "",
  author: "",
  url: "",
  variables: [],
  release: "",
  creating: false,
};

export const project: Module<ProjectState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    reset(state) {
      state.organization = "";
      state.name = "";
      state.author = "";
      state.url = "";
      state.variables = [];
      // state.release = "";
      state.creating = false;
    },
    organization(state, payload: string) {
      state.organization = payload;
    },
    name(state, payload: string) {
      state.name = payload;
    },
    author(state, payload: string) {
      state.author = payload;
    },
    url(state, payload: string) {
      state.url = payload;
    },
    creating(state, payload: boolean) {
      state.creating = payload;
    },
    release(state, payload: string) {
      state.release = payload;
    },
    updateVariables(state, payload: any) {
      state.variables = payload;
    },
    updateVariable(state, payload: any) {
      state.variables = state.variables.filter((f) => f);
      const variable = state.variables.find((f) => f.key === payload.key);
      if (variable) {
        variable.value = payload.value;
      } else {
        state.variables.push({ key: payload.key, value: payload.value });
      }
    },
  },
};
