import server from "../../plugins/axios-helper";

class ProjectService {
  private controller: string = "project";
  private type: ServiceType = ServiceType.Core;
  get(uuid?: string) {
    return new Promise((resolve, reject) => {
      const url = uuid && uuid.length > 0 ? "/find/" + uuid : "/";
      return server
        .get(this.controller + url, {
          masterDatabase: this.type === ServiceType.Master,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  post(model: any) {
    return new Promise((resolve, reject) => {
      return server
        .post(this.controller + "/create", model, {
          masterDatabase: this.type === ServiceType.Master,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  generate(model: any, release: string) {
    return new Promise((resolve, reject) => {
      return server
        .post(this.controller + "/generate/" + release, model, {
          masterDatabase: this.type === ServiceType.Master,
          responseType: "blob",
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  put(uuid: string, model: any) {
    return new Promise((resolve, reject) => {
      return server
        .put(this.controller + "/update/" + uuid, model, {
          masterDatabase: this.type === ServiceType.Master,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  delete(uuid: string) {
    return new Promise((resolve, reject) => {
      return server
        .delete(this.controller + "/delete/" + uuid, {
          masterDatabase: this.type === ServiceType.Master,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
enum ServiceType {
  Master,
  Core,
}
export const projectService = new ProjectService();
