

























































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import SideModal from "@/components/shared/modals/SideModal.vue";
import VueFormGenerator, { validators } from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";
import { Prop } from "vue-property-decorator";
import dateInput from "@/components/shared/forms/DateInput.vue";
import {
  TenantUserRole,
  ITenantUserDTO
} from "../../../../app/models/master/account/ITenantDTO";
import { mapGetters } from "vuex";
import { StripeProduct } from "../../../../app/models/subscription/StripeProduct";
Vue.component("fieldDateInput", dateInput);

@Component({
  components: {
    SideModal,
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
      memberCount: "memberCount",
      role: "role",
      isOwnerOrAdmin: "isOwnerOrAdmin"
    })
  }
})
export default class MembersForm extends BaseComponent {
  @Prop()
  public propTenantApiKey!: string;
  public tenantApiKey: string = "";
  private editing: boolean = false;
  private isValid: boolean = false;
  private id: any = false;
  private model = {} as ITenantUserDTO;
  private disabled: boolean = true;
  private schema: any = {
    fields: [
      {
        id: "email",
        type: "input",
        inputType: "text",
        label: this.$t("account.shared.email"),
        model: "email",
        required: true,
        placeholder: this.$t("account.shared.email"),
        disabled: this.$route.params.id,
        validator: validators.email
      },
      {
        id: "firstName",
        type: "input",
        inputType: "text",
        label: this.$t("settings.profile.firstName"),
        model: "firstName",
        required: true,
        placeholder: this.$t("settings.profile.firstName"),
        validator: validators.string
      },
      {
        id: "phone",
        type: "input",
        inputType: "phone",
        label: this.$t("settings.profile.phone"),
        model: "phone",
        placeholder: this.$t("settings.profile.phone")
      },
      {
        id: "role",
        type: "radios",
        label: this.$t("settings.profile.role"),
        model: "role",
        placeholder: this.$t("settings.profile.role"),
        required: true,
        validator: validators.number,
        values: [
          { name: TenantUserRole[0], value: 0 },
          { name: TenantUserRole[1], value: 1 },
          { name: TenantUserRole[2], value: 2 },
          { name: TenantUserRole[3], value: 3 }
        ]
      }
    ]
  };
  private role!: TenantUserRole;
  private activeProduct!: StripeProduct;
  private memberCount!: number;
  private isOwnerOrAdmin!: boolean;
  private formOptions: any = {
    validateAfterLoad: false,
    validateAfterChanged: true,
    validateAsync: true
  };
  created() {
    this.configureSchema();
  }
  mounted() {
    if (this.propTenantApiKey) {
      this.tenantApiKey = this.propTenantApiKey;
    }
    if (this.$route.params.id) {
      this.disabled = true;
      this.id = this.$route.params.id;
      this.editing = true;
      this.services.tenantUsers
        .getUser(this.id)
        .then((response: any) => {
          this.model = response.data;
          this.disabled = false;
        })
        .catch(error => {
          // // @ts-ignore
          // this.$refs["error-modal"].show(this.$t("shared.notFound"));
          this.$router.push({ path: "/app/settings/organization/members" });
        });
    } else {
      this.disabled = false;
      const maxNumberOfUsers: number =
        this.activeProduct && this.activeProduct.maxUsers
          ? this.activeProduct.maxUsers
          : 0;

      // @ts-ignore
      if (maxNumberOfUsers > 0 && this.memberCount >= maxNumberOfUsers) {
        // @ts-ignore
        this.$refs["confirm-upgrade"].show(
          this.$t("settings.tenant.members.maxNumberOfUsers") +
            " (" +
            maxNumberOfUsers +
            "). " +
            this.$t("shared.upgrade?")
        );
      }
    }
  }
  // getFeatureValue(featureKey: string): number {
  //   if (this.activeProduct && this.activeProduct.features) {
  //     const feature = this.activeProduct.features.find(
  //       (f) => f.key === featureKey
  //     );
  //     if (!feature || !feature.value) {
  //       return 0;
  //     } else {
  //       return Number(feature.value);
  //     }
  //   }
  //   return 0;
  // }
  configureSchema() {
    // this.schema.fields[3].values = this.categories;
  }
  cancel() {
    this.eventBus.$emit("user-canceled");
    this.$router.push({ path: "/app/settings/organization/members" });
  }
  save() {
    // @ts-ignore
    this.$refs.vfg.validate().then(errors => {
      if (errors && errors.length > 0) {
        // @ts-ignore
        this.$refs["error-modal"].show(this.$t("shared.invalidForm"));
      } else {
        // @ts-ignore
        this.$refs["confirm-save"].show(
          this.editing ? this.$t("shared.save?") : this.$t("shared.add?")
        );
      }
    });
  }
  yesSave() {
    if (this.editing) {
      this.services.tenantUsers
        .updateUser(this.model, this.tenantApiKey)
        .then(response => {
          this.eventBus.$emit("user-saved", this.model);
          this.$router.push({ path: "/app/settings/organization/members" });
        })
        .catch(error => {
          // @ts-ignore
          this.$refs["error-modal"].show(error);
        });
    } else {
      this.services.tenantUsers
        .inviteUser(this.model, this.tenantApiKey)
        .then((response: any) => {
          this.eventBus.$emit("user-added", response.data);
          this.$router.push({ path: "/app/settings/organization/members" });
        })
        .catch(error => {
          // @ts-ignore
          this.$refs["error-modal"].show(error);
        });
    }
  }
  yesUpdateSubscription() {
    this.$router.push({ path: "/app/settings/organization/subscription" });
  }
  noUpdateSubscription() {
    this.$router.push({ path: "/app/settings/organization/members" });
  }
  remove() {
    // @ts-ignore
    this.$refs["confirm-remove"].show(this.$t("shared.remove?"));
  }
  yesRemove() {
    if (this.editing) {
      if (this.role === TenantUserRole.Member) {
        // @ts-ignore
        this.$refs["error-modal"].show(this.$t("account.tenant.onlyAdmin"));
      } else {
        this.services.tenantUsers
          .deleteUser(this.model.id)
          .then(response => {
            this.eventBus.$emit("user-deleted", this.model);
            this.$router.push({ path: "/app/settings/organization/members" });
          })
          .catch(error => {
            // @ts-ignore
            this.$refs["error-modal"].show(error);
          });
      }
    }
  }
}
