<template>
  <div v-if="false">
    <div v-if="!closed" class="bg-teal-200 md:block">
      <div
        class="max-w-screen-xl mx-auto py-3 mt-8 md:mt-0 px-3 sm:px-6 lg:px-8"
      >
        <div class="flex items-center justify-between flex-wrap">
          <a
            href="https://appsumo.com/netcoresaas"
            target="_blank"
            class="w-0 flex-1 flex items-center"
          >
            <span class="flex p-2 rounded-sm bg-yellow-200">
              <svg
                class="h-6 w-6 text-teal-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
            </span>
            <p class="ml-3 font-medium text-teal-800 truncate">
              <span class="md:hidden">{{
                $t("marketing.launching.launch")
              }}</span>
              <span class="hidden md:inline">{{
                $t("marketing.launching.getNotified")
              }}</span>
            </p>
          </a>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              @click="closed = true"
              type="button"
              class="-mr-1 flex p-2 rounded-sm hover:bg-teal-500 focus:outline-none focus:bg-teal-500 sm:-mr-2 transition ease-in-out duration-150"
              aria-label="Dismiss"
            >
              <svg
                class="h-6 w-6 text-teal-800"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { closed: false };
  },
};
</script>

<style></style>
