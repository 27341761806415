<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <!-- This example requires Tailwind CSS v2.0+ -->
<div class=" top-0 sticky  bg-blueGray-900 z-50">
  <div class="mx-auto py-3 px-3 sm:px-6 lg:px-8">
    <div class="pr-16 sm:text-center sm:px-16">
      <p class="font-medium text-white flex items-center space-x-2">
        <div class="text-white">
<span>
          Now with Vue3, React and Svelte! If you are an existing NetcoreSaas customer, <a href="https://saasfrontends.com/docs/support" class="underline">contact me</a> and claim -50%.
        </span>
        </div>

        <div class="block sm:ml-2 sm:inline-block">
          <a href="http://saasfrontends.com/" class="text-white font-bold underline"> Learn more <span aria-hidden="true">&rarr;</span></a>
        </div>
      </p>
    </div>
  </div>
</div>

</template>

<script>
export default {
  data() {
    return {
      open: true
    }
  },
  methods: {
    getRoute(item, path) {
      let host = "";
      switch (item) {
        case 0:
          host = "https://starter.vue2.saasfrontends.com";
          break;
        case 1:
          host = "https://starter.vue3.saasfrontends.com";
          break;
        case 2:
          host = "https://starter.react.saasfrontends.com";
          break;
        case 3:
          host = "https://starter.svelte.saasfrontends.com";
          break;
      }
      if (path !== undefined) {
        host += path;
      }
      return host;
    },
    updateRoute(item) {
      window.location.replace(this.getRoute(item));
    }
  },
  computed: {
    getRouteInVue2() {
      return this.getRoute(0, this.$route.path)
    },
    getRouteInVue3() {
      return this.getRoute(1, this.$route.path)
    },
    getRouteInReact() {
      return this.getRoute(2, this.$route.path)
    },
    getRouteInSvelte() {
      return this.getRoute(3, this.$route.path)
    }
  }
}
</script>