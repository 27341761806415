


































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import Header from "@/components/marketing/Header.vue";
import Footer from "@/components/marketing/Footer.vue";
import TabControl from "@/components/shared/containers/TabControl.vue";

@Component({
  metaInfo: {
    title: "Project generator"
  },
  components: {
    Header,
    Footer,
    TabControl
  }
})
export default class BuilderComponent extends BaseComponent {
  selectedIndex: number = 0;
  mounted() {
    this.selectedTab(0);
  }
  selectedTab(index) {
    this.selectedIndex = index;
    // if (index === 0) {
    //   if (this.$route.path !== "/generator/crud") {
    //     this.$router.push({ path: "/generator/crud" });
    //   }
    // } else if (index === 1) {
    //   if (this.$route.path !== "/generator/project") {
    //     this.$router.push({ path: "/generator/project" });
    //   }
    // }
  }
}
