





























































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Selector from "@/components/shared/forms/Selector.vue";
import { mapGetters } from "vuex";
import DarkModeToggle from "./selectors/DarkModeToggle.vue";
import ColorSwitcher from "./selectors/ColorSwitcher.vue";
import FlagSelector from "./selectors/FlagSelector.vue";
import { Watch } from "vue-property-decorator";
import SvgLogo from "../../assets/img/logo2.vue";
import SvgIcon from "../../assets/img/icon.vue";
import DropDownButton from "@/components/shared/buttons/DropDownButton.vue";

@Component({
  components: {
    Selector,
    DarkModeToggle,
    ColorSwitcher,
    FlagSelector,
    SvgLogo,
    SvgIcon,
    DropDownButton
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    })
  }
})
export default class HeaderComponent extends Vue {
  droppedDown: boolean = false;
  currentMarketingTheme: string = "";
  currentMarketingColor: string = "";
  currentPath: string = "";
  mounted() {
    this.currentMarketingTheme = this.$store.state.theme.marketingTheme;
    this.currentMarketingColor = this.$store.state.theme.marketingColor;
  }
  isCurrent(path) {
    return this.$route.path.includes(path);
  }
  generatorClick(index) {
    if (index === 0) {
      this.$router.push({ path: "/generator/crud" });
    } else {
      this.$router.push({ path: "/generator/project" });
    }
  }
  get headerColor() {
    const color =
      this.$store.state.theme.marketingTheme === "theme-bg-dark"
        ? "bg-secondary"
        : "bg-secondary";
    return color;
  }
  get headerColorMobile() {
    const color =
      this.$store.state.theme.marketingTheme === "theme-bg-dark"
        ? "bg-secondary"
        : "bg-primary";
    return color;
  }
}
