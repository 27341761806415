import Vue from 'vue';
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});
Vue.directive('disable-leading-space', { // you can rename it to your liking
    update (el) {
        // using regex to only remove spaces at the beginning
        el.value = el.value.replace(/^\s+/g, ""); // or you can use your own preferred way to remove leading spaces
        el.dispatchEvent(new Event('input'))
    }
})