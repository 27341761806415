import { StripeProduct } from "@/app/models/subscription/StripeProduct";
import { StripeFeature } from "@/app/models/subscription/StripeFeature";
import {
  BillingPeriod,
  PriceType,
} from "@/app/models/subscription/StripePrice";

export const defaultProductsDevelopment: StripeProduct[] = [
  {
    stripeId: "prod_HtU2313RCZv5a9",
    tier: 1,
    title: "NET, Vue and Tailwind",
    description: "The Vue SaaS codebase.",
    prices: [
      {
        stripeId: "price_1HKaFXAlf1asoVHVU9ALPQoS",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 239,
        currency: "usd",
        trialDays: 0,
        active: false,
        priceBefore: 299,
      },
      {
        stripeId: "price_1HKaFXAlf1asoVHVYM3TRvoj",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 4599,
        currency: "mxn",
        trialDays: 0,
        active: false,
        priceBefore: 5799,
      },
      {
        stripeId: "price_1HNtD5Alf1asoVHVfMwjwmqd",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 199,
        currency: "eur",
        trialDays: 0,
        active: false,
        priceBefore: 249,
      },
      {
        stripeId: "",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 299,
        currency: "usd",
        trialDays: 0,
        active: true,
        priceBefore: 0,
      },
      {
        stripeId: "",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 5799,
        currency: "mxn",
        trialDays: 0,
        active: true,
        priceBefore: 0,
      },
      {
        stripeId: "",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 249,
        currency: "eur",
        trialDays: 0,
        active: true,
        priceBefore: 0,
      },
    ],
    features: [
      {
        key: "frontendBackend",
        value: "",
        included: true,
      },
      {
        key: "upToProjects",
        value: "3",
        included: true,
      },
      {
        key: "upToDevelopers",
        value: "3",
        included: true,
      },
      {
        key: "1yearAccess",
        value: "",
        included: true,
      },

      {
        key: "revenue",
        value: "<",
        included: true,
      },

      {
        key: "prioritySupport",
        value: "",
        included: false,
      },
    ],
    badge: "",
    active: true,
    maxUsers: 3,
  },
  {
    stripeId: "",
    tier: 1,
    title: "NET, React and Tailwind",
    description: "The React SaaS codebase.",
    prices: [
      {
        stripeId: "",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 299,
        currency: "usd",
        trialDays: 0,
        active: true,
        priceBefore: 0,
      },
      {
        stripeId: "",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 5799,
        currency: "mxn",
        trialDays: 0,
        active: true,
        priceBefore: 0,
      },
      {
        stripeId: "",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 249,
        currency: "eur",
        trialDays: 0,
        active: true,
        priceBefore: 0,
      },
    ],
    features: [
      {
        key: "frontendBackend",
        value: "",
        included: true,
      },
      {
        key: "upToProjects",
        value: "3",
        included: true,
      },
      {
        key: "upToDevelopers",
        value: "3",
        included: true,
      },
      {
        key: "1yearAccess",
        value: "",
        included: true,
      },

      {
        key: "revenue",
        value: "<",
        included: true,
      },

      {
        key: "prioritySupport",
        value: "",
        included: false,
      },
    ],
    badge: "",
    active: false,
    maxUsers: 3,
  },
  {
    stripeId: "prod_HtU5s19KFK0lnX",
    tier: 2,
    title: "Enterprise 🚀",
    description: "",
    prices: [
      {
        stripeId: "price_1HKaEAAlf1asoVHVqrqBeyd0",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 599,
        currency: "usd",
        trialDays: 0,
        active: true,
        priceBefore: 759,
      },
      {
        stripeId: "price_1HKaEAAlf1asoVHVkNbmyiM1",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 11399,
        currency: "mxn",
        trialDays: 0,
        active: true,
        priceBefore: 14399,
      },
      {
        stripeId: "price_1HNtIuAlf1asoVHVBEb7fbhn",
        type: PriceType.OneTime,
        billingPeriod: BillingPeriod.Once,
        price: 499,
        currency: "eur",
        trialDays: 0,
        active: true,
        priceBefore: 629,
      },
    ],
    features: [
      {
        key: "frontendBackend",
        value: "",
        included: true,
      },
      {
        key: "upToProjects",
        value: "10",
        included: true,
      },
      {
        key: "upToDevelopers",
        value: "10",
        included: true,
      },
      {
        key: "1yearAccess",
        value: "",
        included: true,
      },
      {
        key: "revenue",
        value: ">",
        included: true,
      },
      {
        key: "prioritySupport",
        value: "",
        included: false,
      },
    ],
    badge: "",
    active: false,
    maxUsers: 10,
  },
  {
    stripeId: "prod_Hxob7hufrvhZsb",
    tier: 3,
    title: "Ultimate 🦄",
    description: "",
    prices: [
      {
        stripeId: "price_1HNsy8Alf1asoVHVvtLzkB2C",
        type: PriceType.Recurring,
        billingPeriod: BillingPeriod.Yearly,
        price: 999,
        currency: "usd",
        trialDays: 0,
        active: false,
        priceBefore: 1299,
      },
      {
        stripeId: "price_1HNsy9Alf1asoVHV25IJiosb",
        type: PriceType.Recurring,
        billingPeriod: BillingPeriod.Yearly,
        price: 19999,
        currency: "mxn",
        trialDays: 0,
        active: false,
        priceBefore: 25199,
      },
      {
        stripeId: "price_1HNsy8Alf1asoVHV5RNhs1WX",
        type: PriceType.Recurring,
        billingPeriod: BillingPeriod.Yearly,
        price: 839,
        currency: "eur",
        trialDays: 0,
        active: false,
        priceBefore: 1059,
      },
    ],
    features: [
      {
        key: "frontendBackend",
        value: "",
        included: true,
      },
      {
        key: "unlimitedProjects",
        value: "",
        included: true,
      },
      {
        key: "unlimitedDevelopers",
        value: "",
        included: true,
      },
      {
        key: "1yearAccess",
        value: "",
        included: true,
      },
      {
        key: "revenue",
        value: ">",
        included: true,
      },
      {
        key: "prioritySupport",
        value: "",
        included: true,
      },
    ],
    badge: "",
    active: false,
    maxUsers: 0,
  },
];
