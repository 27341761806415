





























































import Vue from "vue";
import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import SideModal from "@/components/shared/modals/SideModal.vue";
import VueFormGenerator, { validators } from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";
import { Prop } from "vue-property-decorator";
import dateInput from "@/components/shared/forms/DateInput.vue";
import { ColumnType, ValueType } from "../../../app/models/ColumnType";
Vue.component("fieldDateInput", dateInput);

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    SideModal,
    "vue-form-generator": VueFormGenerator.component
  }
})
export default class EntityFormComponent extends BaseComponent {
  @Prop({ default: null })
  public object!: any;
  @Prop()
  public modelName!: string;
  @Prop()
  public columns!: ColumnType[];
  @Prop({ default: true })
  public confirmSave!: boolean;
  @Prop({ default: true })
  public allowDelete!: boolean;
  @Prop({ default: true })
  public translate!: boolean;

  private model: any = null;
  private flagAllowEdit: boolean = true;
  private flagAllowDelete: boolean = true;
  private editing: boolean = false;
  private isValid: boolean = false;
  private errors!: any;
  private disabled: boolean = true;
  private schema: any = {};
  private schemaReady: boolean = false;
  private formOptions: any = {
    validateAfterLoad: false,
    validateAfterChanged: true
  };
  mounted() {
    this.model = this.object;
    if (this.model) {
      this.editing = true;
    } else {
      this.model = VueFormGenerator.schema.createDefaultObject(this.schema);
    }
    this.disabled = false;
    this.generateSchemaFromColumns();
  }
  generateSchemaFromColumns() {
    this.schema.fields = [];
    this.columns.forEach((column: ColumnType) => {
      const allowEdit = !this.editing || !column.cannotEdit;
      const field: any = {
        id: column.name,
        model: column.name,
        type: ColumnType.getFormType(column),
        inputType: ColumnType.getFormInputType(column),
        label: this.$t(`models.${this.modelName}.${column.name}`),
        placeholder: this.$t(`models.${this.modelName}.${column.name}`),
        required: !column.optional && column.valueType !== ValueType.Bool,
        values: ColumnType.getValues(column),
        disabled: !allowEdit,
        validator: "",
        files: false,
        multiple: false,
        preview: true,
        hideInput: false,
        rows: 1,
        default: column.default,
        onChange: (model, schema, event, instance) => {
          console.log("model: " + model);
          console.log("schema: " + schema);
          console.log("event: " + event);
          console.log("instance: " + instance);
        }
      };
      if (!this.translate) {
        field.label = column.name;
        field.placeholder = column.name;
      }
      if (column.title) {
        field.label = column.title;
        field.placeholder = column.title;
      }
      if (column.placeholder) {
        field.placeholder = column.title;
      }
      if (!column.valueType) {
        field.validator = "string";
      }
      if (column.valueType === ValueType.String) {
        field.validator = "string";
      } else if (column.valueType === ValueType.Date) {
        field.validator = validators.date;
      } else if (column.valueType === ValueType.Number) {
        field.validator = "number";
      } else if (column.valueType === ValueType.Bool) {
        field.validator = "";
      } else if (column.valueType === ValueType.Money) {
        field.validator = "double";
      }
      if (
        column.valueType === ValueType.Number ||
        column.valueType === ValueType.PriceType ||
        column.valueType === ValueType.PriceBillingPeriod ||
        column.valueType === ValueType.ResourceFileType
      ) {
        field.default = 0;
        field.validator = "number";
      }
      if (column.valueType === ValueType.File) {
        field.files = true;
        field.multiple = false;
        field.preview = false;
        // field.hideInput = true;
      }

      if (column.valueType === ValueType.TextArea) {
        field.rows = 4;
      }

      if (column.valueType !== ValueType.Hidden) {
        this.schema.fields.push(field);
      }
    });
    this.schemaReady = true;
  }
  cancel() {
    // @ts-ignore
    this.eventBus.$emit(this.modelName + "-canceled", this.model);
    // @ts-ignore
    this.$emit("canceled", this.model);
  }
  onValidated(isValid, errors) {
    // console.log("Validation result: ", isValid, ", Errors:", errors);
    this.isValid = isValid;
    this.errors = errors;
  }
  save() {
    // @ts-ignore
    if (this.$refs.vfg.validate()) {
      if (this.confirmSave) {
        // @ts-ignore
        this.$refs["confirm-save"].show(
          this.editing ? this.$t("shared.save?") : this.$t("shared.add?")
        );
      } else {
        this.yesSave();
      }
    } else {
      // @ts-ignore
      this.$refs["error-modal"].show(this.$t("shared.invalidForm"));
    }
  }
  yesSave() {
    if (this.editing && this.flagAllowEdit) {
      this.eventBus.$emit(this.modelName + "-saved", this.model);
      this.$emit("saved", this.model);
    } else if (!this.editing) {
      this.eventBus.$emit(this.modelName + "-added", this.model);
      this.$emit("added", this.model);
    }
  }
  remove() {
    // @ts-ignore
    this.$refs["confirm-remove"].show(this.$t("shared.remove?"));
  }
  yesRemove() {
    if (this.editing) {
      this.eventBus.$emit(this.modelName + "-deleted", this.model);
      this.$emit("deleted", this.model);
    }
  }
}
