







































































import { faLaughWink } from "@fortawesome/free-solid-svg-icons";
import { Component, Vue } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";

@Component({})
export default class DropDownButtonComponent extends Vue {
  @Prop({ required: true })
  title!: string;
  @Prop({ required: true })
  buttons!: string[];
  @Prop({ required: false, default: "" })
  classes!: string;
  open: boolean = false;
  constructor() {
    super();
  }
  select(index: number) {
    this.$emit("select", index);
    this.closeDropDown();
  }
  toggle() {
    this.open = !this.open;
  }
  closeDropDown() {
    this.open = false;
  }
}
