

























































































































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";

import TenantSubscriptionPlan from "@/components/app/settings/tenant/subscription/TenantSubscriptionPlan.vue";
import TenantSubscriptionProducts from "@/components/app/settings/tenant/subscription/TenantSubscriptionProducts.vue";
import TenantSubscriptionPaymentDetails from "@/components/app/settings/tenant/subscription/TenantSubscriptionPaymentDetails.vue";
import TenantSubscriptionInvoices from "@/components/app/settings/tenant/subscription/TenantSubscriptionInvoices.vue";
import Stripe from "stripe";
import { StripeProduct } from "../../../../../app/models/subscription/StripeProduct";
import {
  StripePrice,
  BillingPeriod,
} from "../../../../../app/models/subscription/StripePrice";
import { mapGetters } from "vuex";

@Component({
  components: {
    ConfirmModal,
    SuccessModal,
    ErrorModal,
    TenantSubscriptionPlan,
    TenantSubscriptionProducts,
    TenantSubscriptionPaymentDetails,
    TenantSubscriptionInvoices,
  },
  computed: {
    ...mapGetters("tenant", {
      activeProduct: "activeProduct",
      activePrice: "activePrice",
    }),
  },
})
export default class TenantSubscription extends BaseComponent {
  stripe!: any;
  elements!: string;
  card!: string;
  mounted() {
    this.services.stripe.getProducts(true, true);
  }
  cancel() {
    // @ts-ignore
    this.$refs["confirm-modal"].show(
      this.$t("settings.tenant.subscription.confirmCancel")
    );
  }
  confirmCancel() {
    return this.services.stripe
      .cancel()
      .then((response) => {
        // @ts-ignore
        this.$refs["success-modal"].show(
          this.$t("settings.tenant.subscription.canceled")
        );
      })
      .catch((error) => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  get products(): StripePrice[] | undefined {
    return this.$store.state.tenant.myProducts as StripePrice[];
  }
  get subscription(): StripePrice | undefined {
    const mySubscriptions = this.products?.filter(
      (f) => f.billingPeriod !== BillingPeriod.Once
    );
    if (mySubscriptions && mySubscriptions.length > 0) {
      return mySubscriptions[0];
    } else {
      return undefined;
    }
  }
}
