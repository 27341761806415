import server from "../../plugins/axios-helper";
import store from "@/store";
import { IUserDTO } from "@/app/models/master/account/IUserDTO";

class UserService {
  updateProfile(payload: IUserDTO) {
    return new Promise((resolve, reject) => {
      server
        .put("user/UpdateProfile/" + store.state.account.user?.id, payload, {
          masterDatabase: true,
        })
        .then((response) => {
          store.commit("account/logged", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  uploadAvatar(avatar: string) {
    return new Promise((resolve, reject) => {
      server
        .put(
          "user/uploadAvatar/" + store?.state.account.user?.id,
          { avatar },
          {
            masterDatabase: true,
          }
        )
        .then((response) => {
          store.commit("account/logged", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  updatePassword(payload: IUserDTO) {
    return new Promise((resolve, reject) => {
      server
        .put("user/UpdatePassword/" + store.state.account.user?.id, payload, {
          masterDatabase: true,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  deleteAccount(email: string) {
    return new Promise((resolve, reject) => {
      server
        .delete("user/deleteAccount/" + email, {
          masterDatabase: true,
        })
        .then((response) => {
          store.dispatch("auth/logout");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export const userService = new UserService();
