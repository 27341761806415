<template>
  <div class="bg-gray-100">
    <div
      class="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
    >
      <div class="grid row-gap-10 mb-8 lg:grid-cols-6">
        <div
          class="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4"
        >
          <div>
            <p class="font-medium tracking-wide text-primary">Product</p>
            <ul class="mt-2 space-y-2">
              <li>
                <router-link
                  to="/product"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Codebase</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  to="/generator"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Generator</router-link
                >
              </li> -->
              <li>
                <router-link
                  to="/pricing"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Pricing</router-link
                >
              </li>
              <!-- <li>
                <router-link
                  to="/account/register"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Register</router-link
                >
              </li> -->
              <!-- <li>
                <router-link
                  to="/account/login"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Login</router-link
                >
              </li> -->
            </ul>
          </div>
          <div>
            <p class="font-medium tracking-wide text-secondary">Support</p>
            <ul class="mt-2 space-y-2">
              <!-- <li>
                <a
                  href="https://docs.netcoresaas.com"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Documentation</a
                >
              </li> -->
              <!-- <li>
                <router-link
                  to="/contact"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Contact</router-link
                >
              </li> -->
              <li>
                <router-link
                  to="/terms"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Terms</router-link
                >
              </li>
              <li>
                <router-link
                  to="/privacy"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Privacy</router-link
                >
              </li>
              <li>
                <router-link
                  to="/faq"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >FAQ</router-link
                >
              </li>
            </ul>
          </div>
          <div>
            <p class="font-medium tracking-wide text-secondary">Follow Us</p>
            <ul class="mt-2 space-y-2">
              <li>
                <a
                  :href="twitter"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Twitter</a
                >
              </li>
              <!-- <li>
                <a
                  :href="slack"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                >Slack Group</a>
              </li> -->
              <!-- <li>
                <a
                  :href="discord"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Discord Server</a
                >
              </li> -->
              <!-- <li>
                <a
                  target="_blank"
                  :href="facebook"
                  class="text-secondary transition-colors duration-300 hover:text-violet-500"
                  >Facebook Group</a
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="mt-12 lg:mt-0 md:max-w-md lg:col-span-2">
          <span class="text-base font-medium tracking-wide text-secondary">
            {{ $t("marketing.newsletter.title") }}
          </span>
          <form
            class="flex flex-col mt-4 md:flex-row"
            @submit.prevent="subscribe()"
          >
            <input
              aria-label="Email address"
              type="email"
              name="EMAIL"
              v-model="email"
              requigray
              class="appearance-none w-full px-5 py-3 border border-gray-400 text-base leading-6 rounded-sm text-violet-900 bg-white placeholder-theme-500 focus:outline-none focus:placeholder-theme-400 transition duration-150 ease-in-out"
              v-bind:placeholder="$t('marketing.newsletter.email')"
            />
            <button
              type="submit"
              class="ml-0 md:ml-2 inline-flex items-center justify-center h-12 px-6 bg-theme-700 font-medium tracking-wide text-white transition duration-200 rounded-sm shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              {{ $t("marketing.newsletter.subscribe") }}
            </button>
          </form>
          <p class="mt-3 text-sm leading-5 text-primary">
            {{ $t("marketing.newsletter.wontSpam") }}
          </p>
        </div>
      </div>
      <div
        class="flex flex-col justify-between pt-5 pb-10 border-t border-gray-800 sm:flex-row"
      >
        <p class="text-sm text-secondary">
          © Copyright 2021 NetcoreSaas. All rights reserved.
        </p>
        <div class="flex items-center mt-4 space-x-4 sm:mt-0">
          <a
            :href="twitter"
            class="text-secondary transition-colors duration-300 hover:text-teal-accent-400"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
              <path
                d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"
              />
            </svg>
          </a>
          <a
            :href="facebook"
            class="text-secondary transition-colors duration-300 hover:text-teal-accent-400"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
              <path
                d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      facebook: "",
      instagram: "",
      twitter: "",
      subscribeURL: "",
      email: "",
      producthunt: "",
      slack: "",
      discord: ""
    };
  },
  mounted() {
    this.facebook = process.env.VUE_APP_SOCIAL_FACEBOOK;
    this.instagram = process.env.VUE_APP_SOCIAL_INSTAGRAM;
    this.twitter = process.env.VUE_APP_SOCIAL_TWITTER;
    this.discord = process.env.VUE_APP_SOCIAL_DISCORD;
    this.producthunt = process.env.VUE_APP_SOCIAL_PRODUCTHUNT;
    this.subscribeURL = process.env.VUE_APP_INTEGRATIONS_NEWSLETTER_MAILCHIMP;
  },
  methods: {
    subscribe() {
      if (this.subscribeURL) {
        window.open(this.subscribeURL + "&EMAIL=" + this.email, "_blank");
      }
    }
  }
};
</script>
