
































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { Project } from "../../../../app/models/core/projects/Project";
import { appThemes, appColors } from "../../../../store/modules/theme/themes";
import { IUserDTO } from "../../../../app/models/master/account/IUserDTO";
import { auth } from "../../../../store/modules/auth";
import { IResourceDTO } from "../../../../app/models/master/resources/IResourceDTO";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: { SuccessModal, ErrorModal, ConfirmModal }
})
export default class ProjectGeneratorComponent extends BaseComponent {
  @Prop({ default: false })
  public currentTheme!: boolean;
  public groups: any[] = [];
  public expanded: number[] = [];
  public selectedRelease = {} as IResourceDTO;
  private appTheme!: string;
  private appColor!: string;
  private item!: Project;
  private releases: IResourceDTO[] = [];
  mounted() {
    console.log("currentTheme: " + this.currentTheme);
    try {
      // @ts-ignore
      this.item = this.$parent.item as Project;
    } catch (e) {
      // ignore
    }
    if (!this.item) {
      this.item = {
        organization: this.$t("models.project.defaultOrganization").toString(),
        name: this.$t("models.project.defaultName").toString(),
        url: this.$t("models.project.defaultURL").toString(),
        variables: []
      };
    }
    this.loadReleases();
  }
  loadReleases() {
    this.loading = true;
    this.services.resources
      .getReleases()
      .then((response: any) => {
        this.releases = response.data;
        if (this.releases && this.releases.length === 1) {
          this.selectedRelease = this.releases[0];
          this.$store.commit("project/release", this.selectedRelease.title);
        }
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  saveRelease() {
    if (this.selectedRelease && this.selectedRelease.title) {
      this.$store.commit("project/release", this.selectedRelease.title);
    }
  }
  recheck(variable) {
    try {
      const smtp = this.groups.find(
        f => f.title === this.$t("app.core.projects.email.smtp")
      );
      smtp.disabled = this.getEmailProvider() !== "SMTP";
      const postmark = this.groups.find(
        f => f.title === this.$t("app.core.projects.email.postmark")
      );
      postmark.disabled = this.getEmailProvider() !== "Postmark";

      const databaseProvider = this.getVariable(
        "PROJECT_CONFIGURATION_DATABASE_PROVIDER"
      );
      const databaseProductionPort = this.getVariable(
        "DATABASE_PRODUCTION_PORT"
      );
      const databaseDevelopmentPort = this.getVariable(
        "DATABASE_DEVELOPMENT_PORT"
      );
      if (databaseProvider) {
        if (databaseProvider.value === "MySQL") {
          if (databaseProductionPort) {
            databaseProductionPort.value = 3306;
          }
          if (databaseDevelopmentPort) {
            databaseDevelopmentPort.value = 3306;
          }
        } else if (databaseProvider.value === "PostgreSQL") {
          if (databaseProductionPort) {
            databaseProductionPort.value = 5432;
          }
          if (databaseDevelopmentPort) {
            databaseDevelopmentPort.value = 5432;
          }
        }
      }

      const product = this.getVariable("PRODUCT_NAME");
      const master = this.getVariable("PROJECT_CONFIGURATION_DATABASE_MASTER");

      if (product && master && (!master.value || master.value === "")) {
        master.value = product.value.replace(/\s/g, "-").toLowerCase();
      }
      if (variable && variable.key === "PRODUCT_NAME") {
        const author = this.getVariable("AUTHOR_NAME");
        const smtpSenderName = this.getVariable("EMAIL_SMTP_SENDER_NAME");
        const postmarkSenderName = this.getVariable(
          "EMAIL_POSTMARK_SENDER_NAME"
        );
        const productUrl = this.getVariable("PRODUCT_URL");
        const herpUrl = this.getVariable("HELP_URL");
        const docsUrl = this.getVariable("DOCS_URL");
        const postmarkEmail = this.getVariable("EMAIL_POSTMARK_SENDER_EMAIL");
        const smtpEmail = this.getVariable("EMAIL_SMTP_SENDER_EMAIL");
        if (author && product) {
          if (
            smtpSenderName &&
            (!smtpSenderName.value || smtpSenderName.value === "")
          ) {
            smtpSenderName.value = this.senderName;
          }
          if (
            postmarkSenderName &&
            (!postmarkSenderName.value || postmarkSenderName.value === "")
          ) {
            postmarkSenderName.value = this.senderName;
          }
        }

        if (product) {
          if (productUrl && (!productUrl.value || productUrl.value === "")) {
            productUrl.value =
              product.value.replace(/\s/g, "-").toLowerCase() + ".com";
          }
          if (herpUrl && (!herpUrl.value || herpUrl.value === "")) {
            herpUrl.value =
              "help." +
              product.value.replace(/\s/g, "-").toLowerCase() +
              ".com";
          }
          if (docsUrl && (!docsUrl.value || docsUrl.value === "")) {
            docsUrl.value =
              "docs." +
              product.value.replace(/\s/g, "-").toLowerCase() +
              ".com";
          }
          if (
            postmarkEmail &&
            (!postmarkEmail.value || postmarkEmail.value === "")
          ) {
            postmarkEmail.value =
              "noreply@" +
              product.value.replace(/\s/g, "-").toLowerCase() +
              ".com";
          }
          if (smtpEmail && (!smtpEmail.value || smtpEmail.value === "")) {
            smtpEmail.value =
              "noreply@" +
              product.value.replace(/\s/g, "-").toLowerCase() +
              ".com";
          }
        }
      }

      if (variable) {
        const saveVariables: any[] = [];
        this.groups.forEach(group => {
          group.variables.forEach(current => {
            saveVariables.push(current);
          });
        });
        this.$store.commit("project/updateVariables", saveVariables);
        // this.updateVariableState(variable);
      }
    } catch (e) {
      // ignore
    }
  }
  randomString(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  getThemes() {
    const themes: any[] = [];
    appThemes.forEach(theme => {
      themes.push({ value: theme.theme, name: theme.name });
    });
    return themes;
  }
  getColors() {
    const colors: any[] = [];
    appColors.forEach(theme => {
      colors.push({ value: theme.theme, name: theme.name });
    });
    return colors;
  }
  getColSpan(group, variable) {
    if (variable.col > 0) {
      return "col-span-1 md:col-span-" + variable.col;
    } else {
      return "col-span-1 md:col-span-" + group.col;
    }
  }
  toggleExpand(group, closeOthers = true) {
    if (this.isExpanded(group)) {
      this.expanded = this.expanded.filter(f => f !== group.id);
    } else {
      if (closeOthers) {
        this.expanded = [];
      }
      this.expanded.push(group.id);
      try {
        const element = document.getElementById(
          group.title.replace(/\s/g, "").toLowerCase()
        );
      } catch (e) {
        // ignore
        console.log("could not scroll: " + e);
      }

      const firstVariable = group.variables.find(f => !f.disabled && !f.type);
      if (firstVariable) {
        const element = this.$refs[firstVariable.key] as HTMLInputElement;
      }
    }
  }
  next(group, add) {
    this.toggleExpand(group);
    let nextGroup;
    let counter = group.id;
    let times = 0;
    while (true) {
      if (times > 2 || nextGroup) {
        break;
      }
      counter += add;
      nextGroup = this.groups.find(f => f.id === counter && !f.disabled);
      times++;
    }

    if (nextGroup) {
      if (!this.isExpanded(nextGroup)) {
        this.toggleExpand(nextGroup);
      }
    } else if (group.id === this.groups[this.groups.length - 1].id) {
      console.log("group: " + group.id);
      console.log("is: " + this.groups[this.groups.length - 1].id);
    }
  }
  isExpanded(group) {
    return this.expanded.includes(group.id);
  }
  updateVariableState(e) {
    // console.log(JSON.stringify(e));
    // if (e.saveInDb) {
    this.$store.commit("project/updateVariable", {
      key: e.key,
      value: e.value
    });
    // }
  }
  getEmailProvider() {
    return this.getVariableValue("PROJECT_CONFIGURATION_EMAIL_PROVIDER");
  }
  getVariable(key): any {
    let found;
    this.groups.forEach(group => {
      group.variables.forEach(variable => {
        if (variable.key === key) {
          found = variable;
        }
      });
    });
    return found;
  }
  public loadVariables(item: Project | null = null) {
    if (item != null) {
      this.item = item;
    }
    this.groups = [
      {
        id: 1,
        icon: "📁",
        title: this.$t("app.core.projects.project.title"),
        description: this.$t("app.core.projects.project.description"),
        cols: 6,
        col: 2,
        variables: [
          {
            title: this.$t("app.core.projects.project.ORGANIZATION_NAME"),
            key: "ORGANIZATION_NAME",
            value: this.item.organization,
            saveInDb: true,
            required: true,
            disabled: false
          },
          {
            title: this.$t("app.core.projects.project.PRODUCT_NAME"),
            key: "PRODUCT_NAME",
            value: this.item.name,
            saveInDb: true,
            required: true,
            disabled: false
          },
          {
            title: this.$t("app.core.projects.project.AUTHOR_NAME"),
            key: "AUTHOR_NAME",
            value: this.user
              ? this.user.firstName + " " + this.user.lastName
              : "",
            saveInDb: true,
            required: true,
            disabled: false
          }
        ]
      },
      {
        id: 2,
        icon: "🔧",
        title: this.$t("app.core.projects.configuration.title"),
        description: this.$t("app.core.projects.configuration.description"),
        cols: 3,
        col: 1,
        variables: [
          {
            title: this.$t(
              "app.core.projects.configuration.PROJECT_CONFIGURATION_DATABASE_PROVIDER"
            ),
            key: "PROJECT_CONFIGURATION_DATABASE_PROVIDER",
            value: "PostgreSQL",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "select",
            options: [
              { name: "PostgreSQL", value: "PostgreSQL" },
              { name: "MySQL", value: "MySQL" }
            ]
          },
          {
            title: this.$t(
              "app.core.projects.configuration.PROJECT_CONFIGURATION_MULTITENANCY"
            ),
            key: "PROJECT_CONFIGURATION_MULTITENANCY",
            value: "SingleDatabase",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "select",
            options: [
              {
                name: this.$t(
                  "app.core.projects.configuration.PROJECT_CONFIGURATION_MULTITENANCY_SingleDatabase"
                ),
                value: "SingleDatabase"
              },
              {
                name: this.$t(
                  "app.core.projects.configuration.PROJECT_CONFIGURATION_MULTITENANCY_DatabasePerTenant"
                ),
                value: "DatabasePerTenant"
              }
            ]
          },
          {
            title: this.$t(
              "app.core.projects.configuration.PROJECT_CONFIGURATION_EMAIL_PROVIDER"
            ),
            key: "PROJECT_CONFIGURATION_EMAIL_PROVIDER",
            value: "Postmark",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "select",
            options: [
              { name: "Postmark", value: "Postmark" },
              { name: "SMTP", value: "SMTP" }
            ]
          },
          {
            title: this.$t(
              "app.core.projects.configuration.PROJECT_CONFIGURATION_DATABASE_MASTER"
            ),
            key: "PROJECT_CONFIGURATION_DATABASE_MASTER",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            col: 3
          }
        ]
      },
      {
        id: 3,
        icon: "🔗",
        title: this.$t("app.core.projects.urls.title"),
        description: this.$t("app.core.projects.urls.description"),
        cols: 4,
        col: 2,
        variables: [
          {
            title: this.$t("app.core.projects.urls.PRODUCT_URL"),
            key: "PRODUCT_URL",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            required: true,
            disabled: false
          },
          {
            title: this.$t("app.core.projects.urls.HELP_URL"),
            key: "HELP_URL",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            required: false,
            disabled: false
          },
          {
            title: this.$t("app.core.projects.urls.DOCS_URL"),
            key: "DOCS_URL",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            required: false,
            disabled: false
          },
          {
            title: this.$t("app.core.projects.urls.SUPPORT_EMAIL"),
            key: "SUPPORT_EMAIL",
            value: this.user ? this.user.email : "",
            saveInDb: true,
            optional: "",
            description: "",
            required: true,
            disabled: false,
            type: "email"
          },
          {
            title: this.$t("app.core.projects.urls.ADMIN_USER_EMAIL"),
            key: "ADMIN_USER_EMAIL",
            value: this.user ? this.user.email : "",
            saveInDb: true,
            optional: "",
            description: "",
            required: true,
            disabled: false,
            type: "email"
          },
          {
            title: this.$t("app.core.projects.urls.ADMIN_USER_PASSWORD"),
            key: "ADMIN_USER_PASSWORD",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            required: true,
            disabled: false,
            type: "password"
          }
        ]
      },
      {
        id: 4,
        icon: "🏦",
        title: this.$t("app.core.projects.authorization.title"),
        description: this.$t("app.core.projects.authorization.description"),
        cols: 4,
        col: 2,
        variables: [
          {
            group: 4,
            title: this.$t("app.core.projects.authorization.JWT_RANDOM16_KEY"),
            key: "JWT_RANDOM16_KEY",
            value: this.randomString(16),
            saveInDb: false,
            optional: "",
            description: this.$t(
              "app.core.projects.authorization.JWT_RANDOM16_KEY_Help"
            ),
            link: "",
            required: true,
            disabled: false
          }
          // {
          //   group: 4,
          //   title: this.$t("app.core.projects.authorization.GOOGLE_ID_OAUTH2"),
          //   key: "GOOGLE_ID_OAUTH2",
          //   value: "",
          //   saveInDb: false,
          //   optional: "",
          //   description: "",
          //   link: "",
          //   required: false,
          //   disabled: false,
          // },
          // {
          //   group: 4,
          //   title: this.$t("app.core.projects.authorization.SECRET_SIGNIN_KEY"),
          //   key: "SECRET_SIGNIN_KEY",
          //   value: this.randomString(20),
          //   saveInDb: false,
          //   optional: "",
          //   description: this.$t(
          //     "app.core.projects.authorization.JWT_RANDOM16_KEY_Help"
          //   ),
          //   link: "",
          //   required: false,
          //   disabled: false,
          // },
        ]
      },
      {
        id: 5,
        icon: "📧",
        title: this.$t("app.core.projects.email.smtp"),
        description: this.$t("app.core.projects.configuration.description"),
        cols: 4,
        col: 2,
        variables: [
          {
            title: this.$t("app.core.projects.email.EMAIL_SMTP_MAIL_SERVER"),
            key: "EMAIL_SMTP_MAIL_SERVER",
            value: "smtp.gmail.com",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false
          },
          {
            title: this.$t("app.core.projects.email.EMAIL_SMTP_MAIL_PORT"),
            key: "EMAIL_SMTP_MAIL_PORT",
            value: "587",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "number"
          },
          {
            title: this.$t("app.core.projects.email.EMAIL_SMTP_SENDER_EMAIL"),
            key: "EMAIL_SMTP_SENDER_EMAIL",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false
          },
          {
            title: this.$t("app.core.projects.email.EMAIL_SMTP_SENDER_NAME"),
            key: "EMAIL_SMTP_SENDER_NAME",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false
          },
          {
            title: this.$t(
              "app.core.projects.email.EMAIL_SMTP_SENDER_PASSWORD"
            ),
            key: "EMAIL_SMTP_SENDER_PASSWORD",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "password"
          }
        ]
      },
      {
        id: 6,
        icon: "📧",
        title: this.$t("app.core.projects.email.postmark"),
        description: this.$t("app.core.projects.configuration.description"),
        cols: 4,
        col: 2,
        variables: [
          {
            title: this.$t(
              "app.core.projects.email.EMAIL_POSTMARK_SERVER_TOKEN"
            ),
            key: "EMAIL_POSTMARK_SERVER_TOKEN",
            value: "",
            saveInDb: false,
            optional: "",
            description: "Get server token 🔑",
            link: "https://account.postmarkapp.com/servers",
            required: true,
            disabled: false
          },
          {
            title: this.$t(
              "app.core.projects.email.EMAIL_POSTMARK_SENDER_NAME"
            ),
            key: "EMAIL_POSTMARK_SENDER_NAME",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false
          },
          {
            title: this.$t(
              "app.core.projects.email.EMAIL_POSTMARK_SENDER_EMAIL"
            ),
            key: "EMAIL_POSTMARK_SENDER_EMAIL",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false
          }
        ]
      },
      {
        id: 7,
        icon: "🏦",
        title: this.$t("app.core.projects.pricing.title"),
        description: this.$t("app.core.projects.pricing.description"),
        cols: 4,
        col: 2,
        variables: [
          {
            group: 3,
            title: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_PUBLIC_KEY_LIVE"
            ),
            key: "SUBSCRIPTION_PUBLIC_KEY_LIVE",
            value: "",
            saveInDb: false,
            optional: "",
            description: "Get live keys 🔑",
            link: "https://dashboard.stripe.com/apikeys",
            required: true,
            disabled: false,
            bg: "yellow-100"
          },
          {
            group: 3,
            title: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_SECRET_KEY_LIVE"
            ),
            key: "SUBSCRIPTION_SECRET_KEY_LIVE",
            value: "",
            saveInDb: false,
            optional: "",
            description: "Get live keys 🔑",
            link: "https://dashboard.stripe.com/apikeys",
            required: true,
            disabled: false,
            bg: "yellow-100",
            type: "password"
          },
          {
            group: 3,
            title: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_PUBLIC_KEY_TEST"
            ),
            key: "SUBSCRIPTION_PUBLIC_KEY_TEST",
            value: "",
            saveInDb: false,
            optional: "",
            description: "Get dev keys 🔑",
            link: "https://dashboard.stripe.com/test/apikeys",
            required: true,
            disabled: false,
            bg: "indigo-100"
          },
          {
            group: 3,
            title: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_SECRET_KEY_TEST"
            ),
            key: "SUBSCRIPTION_SECRET_KEY_TEST",
            value: "",
            saveInDb: false,
            optional: "",
            description: "Get dev keys 🔑",
            link: "https://dashboard.stripe.com/test/apikeys",
            required: true,
            disabled: false,
            bg: "indigo-100"
          },
          {
            group: 3,
            title: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_PUBLIC_KEY_INTEGRATION_TESTS"
            ),
            key: "SUBSCRIPTION_PUBLIC_KEY_INTEGRATION_TESTS",
            value: "",
            saveInDb: false,
            optional: "",
            description: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_SECRET_KEY_INTEGRATION_TESTS_description"
            ),
            link: "https://dashboard.stripe.com/test/apikeys",
            required: true,
            disabled: false,
            bg: "indigo-100"
          },
          {
            group: 3,
            title: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_SECRET_KEY_INTEGRATION_TESTS"
            ),
            key: "SUBSCRIPTION_SECRET_KEY_INTEGRATION_TESTS",
            value: "",
            saveInDb: false,
            optional: "",
            description: this.$t(
              "app.core.projects.pricing.SUBSCRIPTION_SECRET_KEY_INTEGRATION_TESTS_description"
            ),
            link: "https://dashboard.stripe.com/test/apikeys",
            required: true,
            disabled: false,
            bg: "indigo-100"
          }
        ]
      },
      {
        id: 8,
        icon: "🗄️",
        title: this.$t("app.core.projects.database.title"),
        description: this.$t("app.core.projects.database.description"),
        cols: 4,
        col: 2,
        variables: [
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_PRODUCTION_SERVER"
            ),
            key: "DATABASE_PRODUCTION_SERVER",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            bg: "yellow-100"
          },
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_PRODUCTION_PORT"
            ),
            key: "DATABASE_PRODUCTION_PORT",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            bg: "yellow-100",
            type: "number"
          },
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_PRODUCTION_USER"
            ),
            key: "DATABASE_PRODUCTION_USER",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            bg: "yellow-100"
          },
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_PRODUCTION_PASSWORD"
            ),
            key: "DATABASE_PRODUCTION_PASSWORD",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "password",
            bg: "yellow-100"
          },
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_DEVELOPMENT_SERVER"
            ),
            key: "DATABASE_DEVELOPMENT_SERVER",
            value: "localhost",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            bg: "indigo-100"
          },
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_DEVELOPMENT_PORT"
            ),
            key: "DATABASE_DEVELOPMENT_PORT",
            value: "",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            bg: "indigo-100",
            type: "number"
          },
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_DEVELOPMENT_USER"
            ),
            key: "DATABASE_DEVELOPMENT_USER",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            bg: "indigo-100"
          },
          {
            title: this.$t(
              "app.core.projects.database.DATABASE_DEVELOPMENT_PASSWORD"
            ),
            key: "DATABASE_DEVELOPMENT_PASSWORD",
            value: "",
            saveInDb: false,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "password",
            bg: "indigo-100"
          }
        ]
      },
      {
        id: 9,
        icon: "💻",
        title: this.$t("app.core.projects.client.title"),
        description: this.$t("app.core.projects.client.description"),
        cols: 4,
        col: 2,
        variables: [
          {
            title: this.$t("app.core.projects.client.APP_THEME_COLOR"),
            key: "APP_THEME_COLOR",
            value: this.appTheme ?? "theme-bg-dark",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "select",
            options: this.getThemes()
          },
          {
            title: this.$t("app.core.projects.client.APP_PRIMARY_COLOR"),
            key: "APP_PRIMARY_COLOR",
            value: this.appColor ?? "theme-indigo",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "select",
            options: this.getColors()
          },
          {
            title: this.$t("app.core.projects.client.MARKETING_THEME_COLOR"),
            key: "MARKETING_THEME_COLOR",
            value: "theme-bg-light",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "select",
            options: this.getThemes()
          },
          {
            title: this.$t("app.core.projects.client.MARKETING_PRIMARY_COLOR"),
            key: "MARKETING_PRIMARY_COLOR",
            value: this.appColor ?? "theme-indigo",
            saveInDb: true,
            optional: "",
            description: "",
            link: "",
            required: true,
            disabled: false,
            type: "select",
            options: this.getColors()
          }
        ]
      }
      // {
      //   id: 10,
      //   icon: "🧰",
      //   title: this.$t("app.core.projects.integrations.title"),
      //   description: this.$t("app.core.projects.integrations.description"),
      //   cols: 4,
      //   col: 2,
      //   variables: [
      //     {
      //       title: this.$t(
      //         "app.core.projects.integrations.INTEGRATION_INTERCOM"
      //       ),
      //       key: "INTEGRATION_INTERCOM",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get widget key 🔑",
      //       link: "https://app.intercom.com/",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t("app.core.projects.integrations.INTEGRATION_DRIFT"),
      //       key: "INTEGRATION_DRIFT",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get widget key 🔑",
      //       link: "https://app.drift.com/settings2/widget",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t("app.core.projects.integrations.INTEGRATION_GOOGLE"),
      //       key: "INTEGRATION_GOOGLE",
      //       value: "",
      //       saveInDb: true,
      //       optional: "UA-000000000-0",
      //       description: "Get ID 🔑",
      //       link: "https://analytics.google.com",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t(
      //         "app.core.projects.integrations.INTEGRATION_MIXPANEL"
      //       ),
      //       key: "INTEGRATION_MIXPANEL",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get widget key 🔑",
      //       link: "https://mixpanel.com/",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t("app.core.projects.integrations.INTEGRATION_HOTJAR"),
      //       key: "INTEGRATION_HOTJAR",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get tracking ID 🔑",
      //       link: "https://insights.hotjar.com/",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t("app.core.projects.integrations.INTEGRATION_SENTRY"),
      //       key: "INTEGRATION_SENTRY",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get project ID 🔑",
      //       link: "http://sentry.io/",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t(
      //         "app.core.projects.integrations.INTEGRATION_LOGROCKET"
      //       ),
      //       key: "INTEGRATION_LOGROCKET",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get project ID 🔑",
      //       link: "https://app.logrocket.com/",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t(
      //         "app.core.projects.integrations.INTEGRATION_MAILCHIMP"
      //       ),
      //       key: "INTEGRATION_MAILCHIMP",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get mailing list",
      //       link: "https://mailchimp.com/",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t(
      //         "app.core.projects.integrations.INTEGRATION_FORMSPREE"
      //       ),
      //       key: "INTEGRATION_FORMSPREE",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get form ID",
      //       link: "https://formspree.io/forms",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t(
      //         "app.core.projects.integrations.INTEGRATION_GHOST_URL"
      //       ),
      //       key: "INTEGRATION_GHOST_URL",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get blog URL",
      //       link: "http://ghost.io/",
      //       required: false,
      //       disabled: false
      //     },
      //     {
      //       title: this.$t(
      //         "app.core.projects.integrations.INTEGRATION_GHOST_API_KEY"
      //       ),
      //       key: "INTEGRATION_GHOST_API_KEY",
      //       value: "",
      //       saveInDb: true,
      //       optional: "",
      //       description: "Get blog content API Key",
      //       link: "http://ghost.io/",
      //       required: false,
      //       disabled: false
      //     }
      //   ]
      // }
    ];
    this.groups.forEach(group => {
      group.variables.forEach(variable => {
        const current = this.$store.state.project.variables.find(
          f => f.key === variable.key
        );
        if (current && current.value && current.value !== "") {
          variable.value = current.value;
        }
      });
    });
    this.recheck(undefined);
    this.expanded = [];
    this.expanded.push(this.groups[0].id);
  }
  public getVariableValue(key): string {
    const variable = this.getVariable(key);
    if (variable) {
      return variable.value;
    } else {
      return "";
    }
  }
  public validateFormVariables(showErrors: boolean = true) {
    let valid = true;
    this.groups.forEach(group => {
      group.variables.forEach(element => {
        if (!group.disabled && element.required && element.value === "") {
          if (showErrors) {
            // @ts-ignore
            this.$refs["error-modal"].show(
              element.title +
                " " +
                this.$t("shared.required")
                  .toString()
                  .toLowerCase(),
              group.title
            );
            valid = false;
            if (!this.isExpanded(group)) {
              this.toggleExpand(group);
            }
            return false;
          }
        }
        if (!this.item.variables) {
          this.item.variables = [];
        }
        const variable = this.item.variables.find(f => f.key === element.key);
        if (variable) {
          variable.value = element.value;
          variable.saveInDb = element.saveInDb;
        } else {
          this.item.variables.push({
            key: element.key,
            value: element.value,
            saveInDb: element.saveInDb
          });
        }
      });
    });

    return valid;
  }
  @Watch("$store.state.locale.locale")
  watchSomething() {
    this.loadVariables();
  }
  get senderName() {
    const author = this.getVariableValue("AUTHOR_NAME");
    const project = this.getVariableValue("ORGANIZATION_NAME");
    if (author && project) {
      return author + " " + this.$t("shared.from") + " " + project;
    }
    return "";
  }
  get user(): IUserDTO | undefined {
    if (this.$store.state.account && this.$store.state.account.user) {
      return this.$store.state.account.user as IUserDTO;
    }
  }
}
