


















































































































































import Component from "vue-class-component";
import BaseComponent from "../../components/shared/BaseComponent.vue";
import LoadingButton from "@/components/shared/buttons/LoadingButton.vue";
import GoogleSignInButton from "vue-google-signin-button-directive";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import MarketingLogo from "@/components/marketing/MarketingLogo.vue";
import { ITenantDTO } from "../../app/models/master/account/ITenantDTO";
import { mapGetters } from "vuex";
import { defaultState } from "../../store/modules/theme";
import { IVerifyDTO } from "../../app/models/master/account/IVerifyDTO";

@Component({
  components: { ErrorModal, MarketingLogo, LoadingButton },
  directives: {
    GoogleSignInButton
  },
  computed: {
    ...mapGetters("theme", {
      marketingTheme: "marketingTheme",
      marketingColor: "marketingColor"
    }),
    ...mapGetters("account", {
      email: "email"
    })
  }
})
export default class InviteURLComponent extends BaseComponent {
  public requirePhone: boolean = true;
  public user = {} as IVerifyDTO;
  public emailDisabled: boolean = false;
  public tenant = {} as ITenantDTO;
  public email!: string;
  private requested: boolean = false;
  private created() {
    this.user.token = this.$route.params.linkUrl ?? "";
    this.user.email = this.email;
  }
  private mounted() {
    this.user.email = "";
    if (this.email && this.email !== "") {
      this.emailDisabled = true;
      this.user.email = this.email;
    }
    if (this.$route.params.linkUrl) {
      this.loading = true;
      this.$store.commit("theme/marketingTheme", defaultState.marketingTheme);
      this.$store.commit("theme/marketingColor", defaultState.marketingColor);
      this.services.tenantUsers
        .getInviteURL(this.$route.params.linkUrl)
        .then((response: any) => {
          this.tenant = response.data.tenant;

          if (this.tenant.appTheme) {
            this.$store.commit("theme/marketingTheme", this.tenant.appTheme);
          }
          if (this.tenant.appColor) {
            this.$store.commit("theme/marketingColor", this.tenant.appColor);
          }
        })
        .catch(error => {
          if (error.status === 404) {
            // @ts-ignore
            this.$refs["error-modal"].show(this.$t("shared.invalidInvitation"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  private accept() {
    if (this.user.password !== this.user.passwordConfirm) {
      // @ts-ignore
      this.$refs["error-modal"].show(
        this.$t("account.login.errors.passwordMissmatch")
      );
      return;
    }
    // @ts-ignore
    this.$refs.loadingButton.start();
    this.services.tenantUsers
      .requestAccess(this.user)
      .then((response: any) => {
        this.requested = true;
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        // @ts-ignore

        this.$refs.loadingButton.stop();
      });
  }
  private logout() {
    this.$store.commit("auth/logout");
    this.emailDisabled = false;
    this.email = "";
    this.user.email = "";
  }
  get tenantTheme() {
    if (this.tenant) {
      return this.tenant.appTheme + " " + this.tenant.appColor;
    }
  }
}
