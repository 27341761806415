









































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import { ColumnType, ValueType } from "../../../../app/models/ColumnType";
import PageTitle from "@/components/shared/navigation/PageTitle.vue";
import EntityTableList from "@/components/shared/entities/EntityTableList.vue";
import EntityForm from "@/components/shared/entities/EntityForm.vue";
import { mapGetters } from "vuex";
import { StripeProduct } from "../../../../app/models/subscription/StripeProduct";
import { Project } from "../../../../app/models/core/projects/Project";

@Component({
  components: { PageTitle, EntityTableList, EntityForm },
  computed: {
    ...mapGetters("account", {
      isAdmin: "isAdmin"
    }),
    ...mapGetters("tenant", {
      activeProduct: "activeProduct"
    })
  }
})
export default class ProjectListComponent extends BaseComponent {
  private modelName: string = "project";
  private columns: ColumnType[] = [
    { name: "organization", default: this.$store.state.tenant.current.name },
    { name: "name", default: this.$t("models.project.defaultName") },
    { name: "url", default: this.$t("models.project.defaultURL") }
  ];
  private selected = null as any | null;
  private adding: boolean = false;
  private items: any = [];
  private isAdmin!: boolean;
  private activeProduct!: StripeProduct;
  private debug = process.env.NODE_ENV !== "production";
  mounted() {
    this.items = [];
    this.reload();
  }
  reload() {
    this.loading = true;
    this.services.projects
      .get()
      .then((response: any) => {
        this.items = response.data;
        this.creatingProject();
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  creatingProject() {
    if (
      this.$store.state.project.creating &&
      this.$store.state.project.name !== ""
    ) {
      if (false) {
        // @ts-ignore
        this.$refs["error-modal"].show(
          "You cannot create more projects, but you can upgrade!"
        );
        this.$store.commit("project/creating", false);
        return;
      } else {
        const createProject: Project = {
          organization: this.$store.state.project.organization,
          name: this.$store.state.project.name,
          url: this.$store.state.project.author,
          variables: []
        };
        createProject.variables = this.$store.state.project.variables;

        this.added(createProject);
      }
    }
  }
  closeForm(hasChanges = false) {
    this.selected = null;
    this.adding = false;
    if (hasChanges) {
      // @ts-ignore
      this.$parent.hasChanges = true;
    }
  }
  edit(item: any) {
    this.selected = item;
  }
  added(data: any) {
    this.services.projects
      .post(data)
      .then((response: any) => {
        // @ts-ignore
        this.items.push(response.data);
        this.selected = null;
        this.closeForm(true);
        console.log("added");
        this.$router.push({
          path: "/app/projects/generator/" + response.data.uuid
        });
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  saved(data: any) {
    console.log("saved");
    this.services.projects
      .put(data.uuid, data)
      .then(response => {
        // @ts-ignore
        const idx = this.items.findIndex(f => f.uuid === data.uuid);
        this.items[idx] = data;
        this.closeForm(true);
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  deleted(data: any) {
    console.log("deleted");
    this.services.projects
      .delete(data.uuid)
      .then(response => {
        // @ts-ignore
        this.items = this.items.filter(f => f.uuid !== data.uuid);
        this.closeForm(true);
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  canceled() {
    console.log("canceled");
    this.closeForm();
  }
  get numberOfProjectsLeft() {
    if (!this.activeProduct) {
      return 0;
    } else {
      const projects = this.activeProduct.features.find(
        f => f.key === "upToProjects"
      );
      if (!projects) {
        return 100;
      } else {
        const maxProjects = Number(projects.value);
        console.log("maxProjects: " + maxProjects);
        console.log("this.items.length: " + this.items.length);
        return maxProjects - this.items.length;
      }
    }
    return 0;
  }
}
