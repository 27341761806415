import { EntityClass } from '@/app/models/core/builder/EntityClass';
import { EntityProperty } from '@/app/models/core/builder/EntityProperty';
import { EntityCrud } from '@/app/models/core/builder/EntityCrud';
import { ApiService, ApiServiceType } from '../ApiService';

class EntityService extends ApiService {
  constructor() {
    super("Entity", ApiServiceType.TenantDatabase);
  }
  getEntities(uuid?: string): Promise<EntityClass[]> {
    return super.getAll("GetEntities");
  }
  getProperties(uuid?: string): Promise<EntityProperty[]> {
    return super.getAll("GetProperties");
  }
  createClass(model: EntityClass): Promise<EntityClass> {
    return super.post(model, "CreateClass");
  }
  createProperty(model: EntityProperty): Promise<EntityProperty> {
    return super.post(model, "CreateProperty");
  }
  deleteClass(id: any): Promise<any> {
    return super.delete(id, "DeleteClass");
  }
  deleteProperty(id: any): Promise<any> {
    return super.delete(id, "DeleteProperty");
  }
}
export const entitiesService = new EntityService();
