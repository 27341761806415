











































































































































































































































































































































































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import ObjectList from "../../../components/shared/forms/ObjectList.vue";
import { report } from "process";
import TableRowSkeleton from "@/components/shared/skeletons/TableRowSkeleton.vue";
import { SignalService } from "../../../plugins/SignalR";
import { Expense } from "../../../app/models/core/transactions/Expense";

@Component({
  components: { ObjectList, TableRowSkeleton }
})
export default class ExpensesComponent extends BaseComponent {
  private items = [] as Expense[];
  created() {
    const self = this;
    this.eventBus.$on("expense-canceled", () => self.canceled());
    this.eventBus.$on("expense-deleted", data => self.deleted(data));
    this.eventBus.$on("expense-added", data => self.added(data));
    this.eventBus.$on("expense-saved", data => self.saved(data));
  }

  mounted() {
    // SignalService.on("ExpenseAdded", (expense: Expense) => {
    //   this.items.push(expense);
    // });

    this.reload();
  }
  async reload() {
    this.items = [];
    this.loading = true;
    this.services.expenses
      .get()
      .then((response: any) => {
        this.items = response.data;
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  deleted(data: Expense) {
    this.items = this.items.filter(f => f.id !== f.id);
  }
  canceled() {
    console.log("canceled");
  }
  added(data: Expense) {
    // console.log("added data:" + JSON.stringify(data));
    this.items.push(data);
    // SignalService.invoke(
    //   "AddExpense",
    //   this.$store.state.tenant.current.apiKey,
    //   data
    // );
  }
  saved(data: Expense) {
    const idx = this.items.findIndex(f => f.id === data.id);
    this.items[idx] = data;
  }
  get orderedItems() {
    if (!this.items) {
      return [];
    }
    return this.items.sort((x, y) => {
      return (x.date > y.date ? -1 : 1) ?? 1;
    });
  }
}
