






























































































































import Component from "vue-class-component";
import BaseComponent from "../../../components/shared/BaseComponent.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import { StripeProduct } from "../../../app/models/subscription/StripeProduct";
import PageTitle from "../../../components/shared/navigation/PageTitle.vue";
import { adminRoutes } from "../../../router/adminRoutes";
import { Watch } from "vue-property-decorator";
import { ColumnType, ValueType } from "../../../app/models/ColumnType";

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    PageTitle
  }
})
export default class StripeProductComponent extends BaseComponent {
  public product = {} as StripeProduct;
  public hasChanges: boolean = false;

  columns: ColumnType[] = [
    { name: "stripeId" },
    { name: "title" },
    { name: "description" },
    { name: "tier", valueType: ValueType.Number },
    { name: "badge", valueType: ValueType.String },
    { name: "active", valueType: ValueType.Bool },
    { name: "maxUsers", valueType: ValueType.Number },
    { name: "image", valueType: ValueType.Image }
  ];
  created() {
    const self = this;
    this.services.stripe.getProducts(true, false);
    this.eventBus.$on("stripeProduct-deleted", data => self.deleted(data));
    this.eventBus.$on("stripeProduct-saved", data => {
      this.reload();
    });
    this.eventBus.$on("stripeProduct-added", data => {
      this.reload();
    });
  }
  deleted(data: StripeProduct) {
    this.pushToParent();
  }
  @Watch("$route.params.uuid")
  productChanged(val: string, oldVal: string) {
    this.reload();
  }
  mounted() {
    this.reload();
    // this.$router.push({ name: "admin.product.overview" });
  }
  async reload() {
    this.loading = true;
    this.hasChanges = false;
    this.services.stripe
      .getProduct(this.$route.params.uuid)
      .then((response: any) => {
        this.product = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  saveChanges() {
    this.services.stripeAdmin
      .syncProduct(true, this.product)
      .then(response => {
        this.reload();
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  isBool(column: ColumnType) {
    return column.valueType === ValueType.Bool;
  }
  hasImage() {
    return this.columns.includes(
      (element: ColumnType) => element.valueType === ValueType.Image
    );
  }
  getImage() {
    return this.product.image;
  }
  get item() {
    return this.product;
  }
  //   get columns() {
  //     let modelColumns: ColumnType[] = [];
  //     Object.keys(this.product).forEach((key) => {
  //       modelColumns.push({
  //         name: key,
  //       });
  //     });
  //     return modelColumns;
  //   }
}
