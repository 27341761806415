












































































import Component from "vue-class-component";
import BaseComponent from "../../../../components/shared/BaseComponent.vue";
import LoadingButton from "../../../../components/shared/buttons/LoadingButton.vue";
import ErrorModal from "@/components/shared/modals/ErrorModal.vue";
import SuccessModal from "@/components/shared/modals/SuccessModal.vue";
import ConfirmModal from "@/components/shared/modals/ConfirmModal.vue";
import PageTitle from "@/components/shared/navigation/PageTitle.vue";
import { Project } from "../../../../app/models/core/projects/Project";
import { ProjectVariable } from "../../../../app/models/core/projects/ProjectVariable";
import { mapGetters } from "vuex";
import ProjectGenerator from "@/components/app/core/projects/ProjectGenerator.vue";

const fileDownload = require("js-file-download");

@Component({
  components: {
    SuccessModal,
    ErrorModal,
    ConfirmModal,
    PageTitle,
    LoadingButton,
    ProjectGenerator
  },
  computed: {
    ...mapGetters("theme", {
      appTheme: "appTheme",
      appColor: "appColor"
    })
  }
})
export default class ProjectComponent extends BaseComponent {
  public item = {} as Project;
  private existingProject!: boolean;
  mounted() {
    if (this.$route.params.uuid) {
      this.reload();
    } else {
      // @ts-ignore
      this.$refs.generator.loadVariables(this.item);
      // @ts-ignore
      const product = this.$refs.generator.getVariable("PRODUCT_NAME");
      if (product) {
        // @ts-ignore
        this.$refs.generator.recheck(product);
      }
    }
  }
  reload() {
    this.services.projects
      .get(this.$route.params.uuid)
      .then((response: any) => {
        this.existingProject = true;
        this.item = response.data;
        this.creatingProject();
        if (!this.item.variables) {
          this.item.variables = [];
        }
        // @ts-ignore
        this.$refs.generator.loadVariables(this.item);
        this.item.variables.forEach(variable => {
          this.groups.forEach(group => {
            const current = group.variables.find(f => f.key === variable.key);
            if (current) {
              current.value = variable.value;
            }
          });
        });
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  creatingProject() {
    if (
      this.$store.state.project.creating &&
      this.$store.state.project.name !== ""
    ) {
      this.$store.commit("project/creating", false);
      this.$store.commit("project/organization", "");
      this.$store.commit("project/name", "");
      this.$store.commit("project/author", "");
      this.$store.commit("project/url", "");
      this.generate();
    }
  }
  reset() {
    this.$store.commit("project/reset");
    // @ts-ignore
    this.$refs.generator.loadVariables(this.item);
    // @ts-ignore
    const product = this.$refs.generator.getVariable("PRODUCT_NAME");
    if (product) {
      // @ts-ignore
      this.$refs.generator.recheck(product);
    }
  }
  save() {
    this.validateFormVariables(false);
    this.services.projects
      .put(this.item.uuid ?? "", this.item)
      .then((response: any) => {
        // @ts-ignore
        this.$refs["success-modal"].show(this.$t("shared.saved"));
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      });
  }
  public validateFormVariables(showErrors: boolean = true) {
    // @ts-ignore
    return this.$refs.generator.validateFormVariables(showErrors);
  }
  generate() {
    if (this.loading || !this.validateFormVariables()) {
      return;
    }
    if (!this.existingProject) {
      // @ts-ignore
      this.$refs["confirm-modal"].show("Buy");
      return;
    }

    this.loading = true;
    // @ts-ignore
    this.$refs.loadingButton.start();
    this.services.projects
      .generate(this.item, this.$store.state.project.release)
      .then((response: any) => {
        this.$store.commit("project/reset");
        const disposition = response.headers["content-disposition"];
        let fileName = "";

        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const type: string = "application/zip";

        const fileUrl = window.URL.createObjectURL(
          new Blob([response.data], { type })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", this.projectName + ".zip");
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        // @ts-ignore
        this.$refs["error-modal"].show(error);
      })
      .finally(() => {
        this.loading = false;
        // @ts-ignore
        this.$refs.loadingButton.stop();
      });
  }
  yes() {
    this.generate();
  }

  get projectName(): string {
    if (this.item && this.item.name) {
      return (
        this.item.organization.replace(/\s/g, "") +
        "." +
        this.item.name.replace(/\s/g, "")
      );
    }
    return "";
  }
  get groups(): any[] {
    // @ts-ignore
    return this.$refs.generator.groups as any[];
  }
}
