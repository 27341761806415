















































import Component from "vue-class-component";
import BaseComponent from "../../../../../components/shared/BaseComponent.vue";
import Stripe from "stripe";
import {
  StripePrice,
  BillingPeriod
} from "../../../../../app/models/subscription/StripePrice";

@Component({
  components: {}
})
export default class TenantSubscriptionPlan extends BaseComponent {
  get myProducts(): StripePrice[] {
    return (this.$store.state.tenant.myProducts as StripePrice[]).filter(
      f => f.billingPeriod === BillingPeriod.Once
    );
  }
}
