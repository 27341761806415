import server from "../../plugins/axios-helper";
import store from "@/store";

export class CrudService {
  tenantDatabase: boolean = true;
  controller: string;
  constructor(controller: string, tenantDatabase: boolean) {
    this.controller = controller + "";
    this.tenantDatabase = tenantDatabase;
  }
  get(id: number = 0) {
    const url = this.controller + (id > 0 ? "/find/" + id : "");
    return new Promise((resolve, reject) => {
      server
        .get(url, {
          masterDatabase: !this.tenantDatabase,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  post(object: any) {
    return new Promise((resolve, reject) => {
      server
        .post(this.controller, object, {
          masterDatabase: !this.tenantDatabase,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  put(id?: number, object?: any) {
    return new Promise((resolve, reject) => {
      server
        .put(this.controller + "?id=" + id, object, {
          masterDatabase: !this.tenantDatabase,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  delete(id?: number) {
    return new Promise((resolve, reject) => {
      server
        .delete(this.controller + "?id=" + id, {
          masterDatabase: !this.tenantDatabase,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
