






















































import { Component, Vue } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import { Entity } from "../../../app/models/Entity";

@Component({})
export default class CollapsableItem extends Vue {
  @Prop({ required: true })
  items!: any[];
  @Prop({ required: false, default: "name" })
  titleProperty!: string;
  @Prop({ required: false, default: "" })
  prefix!: string;
  @Prop({ required: false, default: "" })
  sufix!: string;
  @Prop({ required: false, default: 0 })
  selectedIndex!: number;
  @Prop({ required: false, default: "sm" })
  size!: string;
  @Prop({ required: false, default: "theme" })
  color!: string;
  public selected: number = 0;
  constructor() {
    super();
  }
  mounted() {
    this.selectTab(this.selectedIndex);
  }
  selectTab(index: number) {
    this.selected = index;
    this.$emit("select", index);
  }
}
